import {
  Directive,
  ElementRef,
  HostListener,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[simplifiMainContainerResize]',
})
export class MainContainerResizeDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.setMainContainerHeight();
  }

  @HostListener('window:resize')
  onResize() {
    this.setMainContainerHeight();
  }

  private setMainContainerHeight() {
    const appHeader = document.getElementById('simpli-header') as HTMLElement;
    const headerHeight = appHeader.offsetHeight;
    const viewportHeight = window.innerHeight;

    const mainContainerHeight = viewportHeight - headerHeight;
    this.el.nativeElement.style.height = `${mainContainerHeight}px`;
  }
}
