import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[simplifiMaxDigits]',
  standalone: true,
})
export class MaxDigitsDirective {
  constructor(private ngControl: NgControl) {}

  /**
   * Prevent the to type extra number beyond maxlength)
   */
  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    if ((event.target as HTMLInputElement).type === 'number') {
      const input = event.target as HTMLInputElement;
      const value = input.value;
      if (value && input.maxLength && value.length > input.maxLength) {
        input.value = value.slice(0, input.maxLength);
        this.ngControl.control?.setValue(input.value);
      }
    }
  }
}
