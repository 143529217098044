import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Words} from '../interfaces';
import {CreatePlanService} from '../services';

@Injectable()
export class AddressableAdapter implements IAdapter<Words> {
  constructor(private readonly createPlanService: CreatePlanService) {}

  /**
   * The function `adaptToModel` takes a response object and converts it into a Words object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `Words` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(resp: any): Words {
    const addressableKeywords: Words = {
      name: '',
      id: '',
    };
    addressableKeywords.id = resp.id;
    addressableKeywords.name = resp.name;
    addressableKeywords.parentId = resp.groupId;
    addressableKeywords.groupName = resp.groupName;
    addressableKeywords.selected = true;
    addressableKeywords.groupId = resp.groupId;
    addressableKeywords.categoryId = resp.categoryId;
    this.createPlanService.plan.addressableIds.push(resp.id);
    return addressableKeywords;
  }

  adaptFromModel(data: Words): any {
    return data;
  }
}
