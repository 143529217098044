import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import {catchError, concatMap, Observable, of} from 'rxjs';
import {AuthService} from '../auth.service';
import {UserSessionStoreService} from '@simplifi/core/store';
import {environment} from '@simplifi/env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: UserSessionStoreService,
  ) {}

  canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree => {
    if (route.queryParamMap.keys.length > 0) {
      const code = route.queryParamMap.get('code');
      if (code) {
        return this.authService
          .authorize(code)
          .pipe(concatMap(() => this._checkLogin(state.url, code)));
      }
    }
    return this._checkLogin(state.url);
  };

  canActivateChild: CanActivateChildFn = (
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> => {
    if (childRoute.queryParamMap.keys.length > 0) {
      const code = childRoute.queryParamMap.get('code');
      if (code) {
        return this._checkLogin(state.url, code);
      }
    }
    return this._checkLogin(state.url);
  };

  private _checkLogin(url: string, code?: string): Observable<boolean> {
    if (this.store.getLastAccessedUrl() && code) {
      this.store.saveLastAccessedUrl(
        `${this.store.getLastAccessedUrl()}?code=${code}`,
      );
    } else {
      this.store.saveLastAccessedUrl(url);
    }
    return this.authService.isLoggedIn().pipe(
      catchError(() => {
        this.router.navigate([environment.loginPath]);
        return of(false);
      }),
    );
  }
}
