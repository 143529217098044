import {Budget} from './budget.model';

export class BudgetTypes {
  aiSuggested!: boolean;
  max: Budget;
  suggested: Budget;
  budgetConfigUpdated!: boolean;
  constructor() {
    this.max = new Budget();
    this.suggested = new Budget();
  }
}
