<div class="dialog-main-wrapper add-tier-group">
  <div mat-dialog-title class="el-dialog__header">
    <span class="el-dialog__title">
      {{ data.title }}
    </span>
    <button
      type="button"
      aria-label="Close"
      class="el-dialog__headerbtn"
      (click)="closeDialog()"
    >
      <i class="el-dialog__close el-icon el-icon-close"></i>
    </button>
  </div>
  <mat-dialog-content>
    <form class="el-form pt-2" #form="ngForm">
      <simplifi-select-box
        [items]="data.items"
        [placeholder]="'Select Organization'"
        [multiple]="true"
        label="Select Tenant"
        [(selectModel)]="data.selectedItems"
        [name]="'status'"
        [bindValue]="'id'"
      ></simplifi-select-box>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions class="form-action el-row--flex is-justify-end gap-x-2">
    <simplifi-input-button
      [mat-dialog-close]="true"
      color="defaultText"
      [name]="'Cancel'"
      (click)="closeDialog()"
    ></simplifi-input-button>
    <simplifi-input-button
      type="basic"
      color="default"
      [name]="'Save'"
      (click)="add()"
      [disabled]="!data.selectedItems?.length"
    ></simplifi-input-button>
  </mat-dialog-actions>
</div>
