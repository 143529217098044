import {BudgetReachDonutType} from '.';
import {TargetMediaType} from './target-media-type.model';

export class Budget {
  planVersionId!: string;
  total = 0;
  allocated = 0;
  targetMediaTypeMappings: TargetMediaType[] = [];
  edit = false;
  amount!: number;
  budgetAllocationType = 'exact';
  isPercentageBudgetAllocation = false;
  reachData: BudgetReachDonutType;
  constructor() {
    this.reachData = new BudgetReachDonutType();
  }
}
