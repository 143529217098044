import {ApiService, GetAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';
export class GetTargetMediaTypeMappings<T> extends GetAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId: string,
    planId: string,
    planVersionId: string,
    strategy: PlanBudgetStrategy,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${planVersionId}/target-media-types/${strategy}`,
    );
  }
}
