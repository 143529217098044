import {ApiService, IAdapter, PostAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';

export class UnsubsribeChannelCommand<T> extends PostAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId: string,
    channelId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.chatFacadeApiUrl}/tenants/${tenantId}/channels/${channelId}/unsubscribe`,
    );
  }
}
