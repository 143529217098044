import {Injectable} from '@angular/core';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {Observable} from 'rxjs';
import {AddressableDemographics, Words} from '../interfaces';
import {
  GetAddressables,
  GetPlanAddressables,
  DeletePlanAddressable,
  AddEditPlanTargets,
} from '../commands';
import {HttpParams} from '@angular/common/http';
import {UserSessionStoreService} from '@simplifi/core/index';
import {AddressableAdapter} from '../adapters';
import {GetAddressableGroupDemos} from '../commands/get-addressable-group-demos.command';

/**
 * Service for managing addressable entities related to plans and versions.
 */
@Injectable()
export class AddressableFacadeService {
  /**
   * Constructs an instance of `AddressableFacadeService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {AnyAdapter} anyAdapter - The adapter for handling generic data.
   * @param {UserSessionStoreService} store - The user session store service.
   * @param {AddressableAdapter} addressableAdapter - The adapter for handling addressable data.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly store: UserSessionStoreService,
    private readonly addressableAdapter: AddressableAdapter,
  ) {}

  /**
   * Retrieves addressables based on the provided parameters.
   *
   * @param {string} tenantId - The ID of the tenant.
   * @param {string} body - The user statement to be used for retrieving addressables.
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the retrieved addressables.
   */
  getAddressable(
    tenantId: string,
    body: string,
    planId: string,
    versionId: string,
  ): Observable<Words[]> {
    const command: GetAddressables<Words[]> = new GetAddressables(
      this.apiService,
      this.anyAdapter,
      tenantId,
      planId,
      versionId,
    );
    command.parameters = {
      data: {
        user_statement: body.trim(), //NOSONAR
        stateSessionId: this.store.getStateSession(),
      },
    };
    return command.execute();
  }

  /**
   * Retrieves addressables associated with a specific plan and version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the plan addressables.
   */
  getPlanAddressable(planId: string, versionId: string): Observable<Words[]> {
    const command: GetPlanAddressables<Words> = new GetPlanAddressables(
      this.apiService,
      this.addressableAdapter,
      planId,
      versionId,
      this.store.getUser().defaultTenantId,
    );
    if (this.store.getStateSession()) {
      command.parameters = {
        query: new HttpParams().set(
          'stateSessionId',
          this.store.getStateSession(),
        ),
      };
    }

    return command.execute();
  }

  getAddressableGroupDemos(): Observable<AddressableDemographics[]> {
    const command: GetAddressableGroupDemos<AddressableDemographics> =
      new GetAddressableGroupDemos(
        this.apiService,
        this.anyAdapter,
        this.store.getUser().defaultTenantId,
      );
    return command.execute();
  }

  /**
   * Adds or edits targets for a specific plan version.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   *
   * @returns {Observable<void>} - An observable that completes when the operation is successful.
   */
  addEditTargets(versionId: string, planId: string) {
    const command: AddEditPlanTargets<Words> = new AddEditPlanTargets(
      this.apiService,
      this.anyAdapter,
      planId,
      versionId,
      this.store.getUser().defaultTenantId,
    );

    command.parameters = {
      data: {
        stateSessionId: this.store.getStateSession(),
      },
    };

    return command.execute();
  }

  /**
   * Deletes an addressable entity or all addressables based on the provided parameters.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   * @param {Words} addressable - The addressable entity to be deleted.
   *
   * @returns {Observable<void>} - An observable that completes when the deletion is successful.
   */
  deleteAddressable(versionId: string, planId: string, addressable: Words) {
    const command: DeletePlanAddressable<Words> = new DeletePlanAddressable(
      this.apiService,
      this.anyAdapter,
      planId,
      versionId,
      this.store.getUser().defaultTenantId,
    );
    command.parameters = {};
    if (addressable?.id && addressable?.isParent) {
      command.parameters['data'] = {
        id: addressable?.id,
        groupId: addressable?.groupId,
        categoryId: addressable?.categoryId,
        isParent: addressable?.isParent,
        stateSessionId: this.store.getStateSession(),
      };
    } else if (addressable?.id && !addressable?.isParent) {
      command.parameters['data'] = {
        id: addressable?.id,
        groupId: addressable?.groupId,
        categoryId: addressable?.categoryId,
        stateSessionId: this.store.getStateSession(),
      };
    } else {
      command.parameters['data'] = {
        stateSessionId: this.store.getStateSession(),
      };
      command.parameters['query'] = new HttpParams().set('deleteAll', true);
    }
    return command.execute();
  }
}
