import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import {Observable} from 'rxjs';

import {ReCaptchaV3Service} from 'ng-recaptcha-2';
import {switchMap, tap} from 'rxjs/operators';
import {CaptchaTokenHeader, RecaptchaSkipHeader} from '../constants';

@Injectable()
export class CaptchaInterceptor implements HttpInterceptor {
  constructor(private readonly recaptchaV3Service: ReCaptchaV3Service) {}
  captchaToken!: string;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (
      req.headers.has(CaptchaTokenHeader) &&
      !req.headers.has(RecaptchaSkipHeader)
    ) {
      const headers = req.headers.delete(CaptchaTokenHeader);
      return this._getRecaptchaToken().pipe(
        switchMap(() =>
          next.handle(
            req.clone({headers: headers.set('recaptcha', this.captchaToken)}),
          ),
        ),
      );
    } else {
      return next.handle(req);
    }
  }

  _getRecaptchaToken() {
    return this.recaptchaV3Service.execute('importantAction').pipe(
      tap(res => {
        this.captchaToken = res;
      }),
    );
  }
}
