import {DonutDefaultConfig} from '.';
export class DonutDataset {
  label!: string;
  chartLabels!: string[];
  data!: number[];
  backgroundColor!: string[];
  hoverBackgroundColor!: string[];
  borderWidth!: number;
  donutTotalText!: string;
  donutTotalValue!: string;
  donutDefaultConfig: DonutDefaultConfig;
  chartId!: string;
  constructor() {
    this.label = 'Budget Breakdown';
    this.chartLabels = [];
    this.data = [];
    this.backgroundColor = ['#00D1FF', '#F58AB7', '#099B7F', '#754AD0'];
    this.hoverBackgroundColor = ['#00D1FF', '#F58AB7', '#099B7F', '#754AD0'];
    this.borderWidth = 0;
    this.donutTotalText = 'Total';
    this.donutTotalValue = '0';
    this.donutDefaultConfig = new DonutDefaultConfig();
  }
}
