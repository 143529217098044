import {NameId} from '@simplifi/core/api';
import {IAnyObject} from '@simplifi/core/i-any-object';

export class Message {
  id!: string;
  text!: string;
  date!: Date;
  reply!: boolean;
  type!: string;
  user!: NameId;
  systemGenerated?: boolean;
  parentId?: string;
  quote?: string;
  customMessageData?: object;
  aiResponse!: boolean;
  customAction?: string;
  metadata?: IAnyObject;
}
