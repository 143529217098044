import {Injectable} from '@angular/core';
import {ApiModule} from '../api.module';
import {IAdapter} from './i-adapter';
import {isEmpty} from 'lodash';

@Injectable({
  providedIn: ApiModule,
})
export class StringToNumberAdapter implements IAdapter<any> {
  adaptToModel(resp: any): any {
    return resp;
  }
  adaptFromModel(obj: any): any {
    return this.convertValuesToNumber(obj);
  }

  /**
   * Converts all string, boolean, and bigint values within an object or array to numbers where possible.
   *
   * This method recursively traverses the input, converting values to numbers if they can be successfully parsed.
   *
   * - For arrays, each element is processed recursively.
   * - For objects, each property value is processed recursively.
   * - For strings, booleans, and bigints, an attempt is made to convert the value to a number.
   * - If a value cannot be converted to a number (e.g., it results in NaN), the original value is retained.
   * - Numbers and other types are returned as-is.
   *
   * @param obj - The input object, array, or value to be converted.
   * @returns The input with all applicable values converted to numbers.
   */
  convertValuesToNumber(obj: any): any {
    switch (true) {
      case Array.isArray(obj): {
        // Handle arrays by recursively converting their elements
        return obj.map(item => this.convertValuesToNumber(item));
      }

      case typeof obj === 'object' && !isEmpty(obj): {
        // Handle objects by recursively converting each property
        return Object.keys(obj).reduce(
          (acc, key) => {
            acc[key] = this.convertValuesToNumber(obj[key]);
            return acc;
          },
          {} as {[key: string]: any},
        );
      }

      case ['string', 'boolean', 'bigint'].includes(typeof obj): {
        // Handle strings, booleans, and bigints by attempting to convert them to a number
        let num = Number(obj);
        return isNaN(num) ? obj : num;
      }

      default: {
        // Return the value as-is for numbers and other types
        return obj;
      }
    }
  }
}
