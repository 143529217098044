import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CellClickService {
  private readonly clickSubject = new BehaviorSubject<object>({});
  clickData = this.clickSubject.asObservable();

  public setData(clickData: object) {
    this.clickSubject.next(clickData);
  }
}
