import {Injectable} from '@angular/core';
import {
  AnyAdapter,
  ApiService,
  UserSessionStoreService,
} from '@simplifi/core/index';
import {UnsubsribeChannelCommand} from '../commands';

@Injectable({
  providedIn: 'root',
})
export class PlanOperationsFacadeService {
  constructor(
    private readonly apiService: ApiService,
    private readonly sessionStore: UserSessionStoreService,
    private readonly anyAdapter: AnyAdapter,
  ) {}

  unsubsribeChatChannel(channelId: string) {
    const command: UnsubsribeChannelCommand<string> =
      new UnsubsribeChannelCommand(
        this.apiService,
        this.anyAdapter,
        this.sessionStore.getUser()?.defaultTenantId ?? '',
        channelId,
      );
    command.parameters = {
      data: {
        channelId,
      },
    };
    return command.execute();
  }
}
