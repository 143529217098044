import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'simplifi-plan-version-creation-dialog',
  templateUrl: './plan-version-creation-dialog.component.html',
  styleUrl: './plan-version-creation-dialog.component.scss',
})
export class PlanVersionCreationDialogComponent {
  versionTag = '';
  versionSequence = '';
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      version: string;
    },
    public dialogRef: MatDialogRef<PlanVersionCreationDialogComponent>,
  ) {
    this.calculateNextSequence(data.version);
  }
  onClick(value: boolean) {
    this.dialogRef.close({
      isSaved: value,
      versionTag: this.versionTag,
    });
  }

  calculateNextSequence(version = '1.0.0') {
    const stringArr = version.split('.');
    stringArr[0] = String(+stringArr[0] + 1);
    this.versionSequence = `VERSION ${stringArr.join('.')}`;
  }
}
