export enum StoreKeys {
  ACCESS_TOKEN_KEY = 'accessToken',
  REFRESH_TOKEN_KEY = 'refreshToken',
  USER_KEY = 'user',
  PLAN_KEY = 'plan',
  CHANNEL_GROUPS = 'channelGroups',
  ASK_CHANNEL = 'askChannel',
  PUBNUB_KEYS = 'pubnubKeys',
  CALL_DATA = 'callData',
  REMEMBER_ME_DATA = 'rememberMeData',
  SESSION_TIMEOUT = 'sessionTimeout',

  LAST_ACTIVITY = 'lastActivity',
  USER_EMAIL = 'userEmail',
  WELCOME_DIALOG = 'welcomeDialog',
  TIME_ZONE = 'timezone',

  MEDIA_MIX_ASK_CHANNEL = 'mediaMixAskChannel',
  LAST_ACCESSED_URL = 'lastAccessedUrl',
  TOKEN_EXPIRY = 'tokenExpiry',
  PAGE_SETTINGS = 'pageSettings',

  STATE_SESSION_ID = 'stateSessionId',
  ENABLE_PLAN_DRAFT_CHECK = 'enablePlanDraftCheck',
  ENABLE_DEFAULT_MESSAGES_CHECK = 'enableDefaultMessagesCheck',
  SET_BUDGET_STRATEGY = 'setBudgetStrategy',
}
