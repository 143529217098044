import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Item} from '@simplifi/shared/interfaces';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
  selector: 'simplifi-dropdown-dialog',
  templateUrl: './dropdown-dialog.component.html',
  styleUrl: './dropdown-dialog.component.scss',
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DropdownDialogComponent<T> {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      items: Item<T>[];
      selectedItems: string[];
    },
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.data.selectedItems);
  }
}
