import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'simplifi-switch',
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.scss',
})
export class SwitchComponent {
  @Input() id!: string;
  @Input() label = '';
  @Input() isChecked = true;
  @Input() isDisabled = false;
  @Output() switchModelChange = new EventEmitter();

  toggleSwitch() {
    this.switchModelChange.emit(!this.isChecked);
  }
}
