<div
  class="el-form-item"
  [ngClass]="{'horizontal-field': horizontalField}"
  [ngSwitch]="type"
>
  <ng-container *ngSwitchDefault>
    <label *ngIf="inputLabel" class="el-form-item__label flex" for="{{ id }}">
      {{ label }}
      <i
        *ngIf="suffixLabelIcon"
        [matTooltip]="showTooltip ? showTooltip : ''"
        class="ml-2 el-input__icon {{ suffixLabelIcon }}"
      ></i>
    </label>
    <div class="el-form-item__content">
      <div
        class="el-input"
        [class]="{
          'el-input--prefix': prefixIcon || prefixSvg || prefixText,
          'el-input--suffix':
            suffixIcon || suffixSvg || suffixText || isSearchInput,
          'is-disabled': disabled,
        }"
      >
        <span *ngIf="prefixIcon" class="el-input__prefix">
          <i class="el-input__icon {{ prefixIcon }}"></i>
        </span>
        <span *ngIf="prefixText" class="el-input__prefix input-prefix-text">
          {{ prefixText }}
        </span>
        <span *ngIf="prefixSvg" class="el-input__prefix input-prefix-svg">
          <img class="el-input__icon" [src]="prefixSvg" alt="" />
        </span>
        <input
          #rootInput
          class="el-input__inner"
          [type]="type"
          maxWidth="maxWidth"
          fullWidth
          [(ngModel)]="inputModel"
          name="{{ name || 'name' }}"
          [id]="id"
          [pattern]="pattern"
          [placeholder]="placeholder"
          [required]="required"
          (ngModelChange)="onChange($event)"
          [disabled]="disabled"
          maxlength="{{ maxlength }}"
          simplifiTrim
          simplifiNumber
          simplifiMaxDigits
          simplifiPreventInputkey
          [mask]="mask"
          [simplifiMinValue]="minValue"
          (focusout)="focusOut()"
        />
        <span *ngIf="suffixSvg" class="el-input__suffix input-suffix-svg">
          <img class="el-input__icon" [src]="suffixSvg" alt="" />
        </span>
        <span *ngIf="suffixIcon" class="el-input__suffix">
          <i class="el-input__icon {{ suffixIcon }}"></i>
        </span>
        <span *ngIf="suffixText" class="el-input__suffix input-suffix-text">
          {{ suffixText }}
        </span>
        <button
          *ngIf="isSearchInput && isString(inputModel) && inputModel.length > 0"
          type="button"
          matTooltip="Clear"
          class="el-input__suffix clear-button"
          aria-label="Clear input"
          (click)="clearInput()"
          (keyup.enter)="clearInput()"
          (keyup.space)="clearInput()"
        >
          <i class="el-icon-close-small"></i>
        </button>
        <div class="el-form-item__label flex" *ngIf="inputHelpText">
          {{ inputHelpText }}
        </div>
      </div>
      <div
        *ngIf="
          form?.form?.controls?.[name]?.invalid &&
          form.form.controls[name]?.errors?.['required'] &&
          form.form.controls[name]?.touched
        "
        class="el-form-item__error"
      >
        {{ label ?? validationMessage }} Is Required
      </div>
      <div
        *ngIf="
          form?.form?.controls?.[name]?.invalid &&
          form.form.controls[name]?.errors?.['pattern'] &&
          form.form.controls[name]?.touched
        "
        class="el-form-item__error"
      >
        <span *ngIf="validationMessage; else invalidLabel">
          {{ validationMessage }}
        </span>
        <ng-template #invalidLabel> Invalid {{ label }} </ng-template>
      </div>
    </div>
  </ng-container>
</div>
