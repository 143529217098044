import {Injectable} from '@angular/core';
import {GetPlanDraftsCommand, DeletePlanDraftsCommand} from '../commands';
import {Observable} from 'rxjs';
import {AnyAdapter, ApiService} from '@simplifi/core/index';

@Injectable()
export class PlanDraftsFacadeService {
  constructor(
    private readonly apiService: ApiService,
    private readonly anyadapter: AnyAdapter,
  ) {}

  getPlanDraftCount(
    tenantId: string,
  ): Observable<{draftResponseMessage: string; percentage: number}> {
    const command: GetPlanDraftsCommand<{
      draftResponseMessage: string;
      percentage: number;
    }> = new GetPlanDraftsCommand(this.apiService, this.anyadapter, tenantId);

    return command.execute();
  }

  deletePlanDrafts(tenantId: string) {
    const command = new DeletePlanDraftsCommand(
      this.apiService,
      this.anyadapter,
      tenantId,
    );

    return command.execute();
  }
}
