export class DonutDefaultConfig {
  fontSizeText: number;
  fontSizeValue: number;
  fillStyleText: string;
  fillStyleValue: string;
  constructor() {
    this.fontSizeText = 12;
    this.fontSizeValue = 24;
    this.fillStyleText = '#191919';
    this.fillStyleValue = '#191919';
  }
}
