import {Component} from '@angular/core';
import {IHeaderParams} from 'ag-grid-enterprise';

@Component({
  selector: 'simplifi-custom-table-header',
  templateUrl: './custom-table-header.component.html',
  styleUrl: './custom-table-header.component.scss',
})
export class CustomTableHeaderComponent {
  params!: IHeaderParams;

  agInit(params: IHeaderParams): void {
    this.params = params;
  }
}
