import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {InputButtonDropdown} from '../../interfaces';
import {SelectBoxDropwdownValue} from '@simplifi/shared/types';

@Component({
  selector: 'simplifi-input-button',
  templateUrl: './input-button.component.html',
  styleUrls: ['./input-button.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InputButtonComponent {
  @ViewChild('simpliFiBtn') simpliFiBtn!: ElementRef<HTMLButtonElement>;
  @Input() type: 'basic' | 'flat' | 'raised' | 'stroked' = 'basic';
  @Input() width = '';
  @Input() name = '';
  @Input() color = 'default'; // Default color can be set here
  @Input() disabled = false;
  @Input() leftIcon?: string;
  @Input() leftSvg = '';
  @Input() rightIcon?: string;
  @Input() hasCount?: number;
  @Input() isMenuButton = false;
  @Input() xPosition: 'before' | 'after' = 'before';
  @Input() menuBtnColor = '';
  @Input() menuOptions: InputButtonDropdown[] = [];
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Output() clickButton = new EventEmitter();
  @Output() menuOptionSelected = new EventEmitter();

  getClass(): string {
    const sizeClass = this.getSizeClass(this.size);

    switch (this.color) {
      case 'primary':
        return this.checkDisabled(`el-button--primary ${sizeClass}`);
      case 'default':
        return this.checkDisabled(`el-button--default ${sizeClass}`);
      case 'info':
        return this.checkDisabled(`el-button--info ${sizeClass}`);
      case 'danger':
        return this.checkDisabled(`el-button--danger ${sizeClass}`);
      case 'defaultText':
        return this.checkDisabled(`el-button--text ${sizeClass}`);
      case 'dangerText':
        return this.checkDisabled(
          `el-button--danger el-button--text ${sizeClass}`,
        );
      case 'draft':
        return this.checkDisabled(
          `el-button--text el-button--draft is-round ${sizeClass}`,
        );
      case 'black':
        return this.checkDisabled(
          `el-button--text el-button--black ${sizeClass}`,
        );
      case 'primaryText':
        return this.checkDisabled(
          `el-button--text el-button-primary-text ${sizeClass}`,
        );
      default:
        return sizeClass; // Handle unexpected or absent color values
    }
  }

  getSizeClass(size: string): string {
    switch (size) {
      case 'small':
        return ' el-button--small ';
      case 'medium':
        return ' el-button--medium ';
      case 'large':
        return ' el-button--large ';
      default:
        return '';
    }
  }

  checkDisabled(colouredClass: string): string {
    let classes = 'el-button ';
    classes += colouredClass;
    if (this.disabled) {
      classes += ' is-disabled';
    }
    return classes;
  }

  clicked() {
    this.simpliFiBtn.nativeElement.blur();
    this.clickButton.emit();
  }

  onMenuOptionSelected(value?: SelectBoxDropwdownValue) {
    this.menuOptionSelected.emit(value);
  }
}
