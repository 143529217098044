import {ApiService, IAdapter, PatchAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class UpdatePlanVersion<T> extends PatchAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId: string,
    planId: string,
    planVersionId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${planVersionId}`,
    );
  }
}
