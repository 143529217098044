import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[simplifiNumber]',
  standalone: true,
})
export class NumberDirective {
  constructor(private el: ElementRef) {}

  /**
   * Prevent the default action (typing "e" into the number input)
   */
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    if (
      (this.el.nativeElement as HTMLInputElement).type === 'number' &&
      (event.key === 'e' ||
        event.key === '-' ||
        event.key === '+' ||
        event.key === '.')
    ) {
      event.preventDefault();
    }
  }
}
