import {TacticsMediaFrequencyConfig} from './tactics-media-frequency-config.model';

export class TacticsFrequencyConfig {
  keyword: TacticsMediaFrequencyConfig;
  ztv: TacticsMediaFrequencyConfig;
  contextual: TacticsMediaFrequencyConfig;
  addressable: TacticsMediaFrequencyConfig;
  constructor() {
    this.keyword = new TacticsMediaFrequencyConfig();
    this.ztv = new TacticsMediaFrequencyConfig();
    this.contextual = new TacticsMediaFrequencyConfig();
    this.addressable = new TacticsMediaFrequencyConfig();
  }
}
