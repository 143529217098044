import {Component, Input} from '@angular/core';

@Component({
  selector: 'simplifi-custom-overlay',
  templateUrl: './custom-overlay.component.html',
  styleUrls: ['./custom-overlay.component.scss'],
})
export class CustomOverlayComponent {
  @Input() isOpen = false;
  @Input() position: 'left' | 'right' = 'left'; // Default position is 'left'

  toggleOverlay() {
    this.isOpen = !this.isOpen;
  }
}
