/* eslint-disable */
export const PATTERNS = {
  alphabetsOnlyPattern: '^[a-zA-Z ]*$',
  numberOnlyPattern: '^[0-9]*$',
  floatOnlyPattern: '^[0-9]*.?[0-9]*$',
  floatOnlyTwoDecimalPattern: '^[0-9]+(.[0-9]{1,2})?$',
  namePattern: '^([a-zA-Z .-]){3,}[a-zA-Z ]*$',
  emailPattern: `^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$`,
  emailArrayPattern: `[^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,},]$`,
  phonePattern: `^[+][1-9]{1,3}[0-9]{1,15}$`,
  phonePatternUsaCanada: `^[+][1][0-9]{10}$`,
  passwordPattern: `^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}`,
  alphaNumericOnlyPattern: '^[a-zA-Z0-9 ]*$',
  alphaNumericOnlyMaxLengthPattern: '^[a-zA-Z0-9 ]{1,250}$',
  freeTextField: '^.{1,250}$',
  alphaNumericSplOnlyPattern: "^[a-zA-Z0-9@#$%^&*()_+-={}![]:;'<>,.?|\\]*$",
  zipPatternUSA: '^[0-9]{5}(?:-[0-9]{4})?$',
  zipPatternOthers: '^.{1,10}$',
  subDomainPattern: '^[a-zA-Z0-9][a-zA-Z0-9-]*$',
  commaSeparatedStringPattern: `^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}(?:,s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,})*$`,
  commaSeparatedEmail: `^([a-z][a-z0-9_.]+@[a-z0-9-]+[.]+[a-z]{2,6}(, )*)+$`,
  commaSeparatedEmailWithCapital: `^([a-zA-Z][a-zA-Z0-9_.]+@[a-zA-Z0-9-]+[.]+[a-zA-Z]{2,6}(,)*)+$`,
  commaSeparatedZipCodes: `^(?:[0-9]{5}(, |,)*){1,50}$`,
  characterLimit: `^.{1,250}$`,
  urlPattern: `^(https?:\\/\\/)?(www\\.)?[a-zA-Z0-9-]+(\\.[a-zA-Z]{2,})+(\\/.*)?$`,
  planNamePattern: '^[a-zA-Z0-9 ]{4,250}$',
  phonePatternSimpliFi: '^[0-9]{10}?$',
  decimalNumberPattern: '\\d+(\\.\\d{1,2})?',
};
