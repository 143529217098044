<ng-template #button>
  <div [ngSwitch]="type" #buttpn>
    <ng-container *ngSwitchCase="'basic'">
      <button
        #simpliFiBtn
        class="el-button"
        [ngClass]="getClass()"
        [style.width]="width"
        type="button"
        (click)="clicked()"
        [disabled]="disabled"
      >
        <i class="mr-2" *ngIf="leftIcon" [class]="leftIcon"></i>
        <img class="mr-2" *ngIf="leftSvg" [src]="leftSvg" alt="" />
        <span class="btn-text">{{ name }}</span>
        <div
          *ngIf="hasCount"
          class="el-badge els-badge--inline els-badge--right"
        >
          <sup class="el-badge__content el-badge__content--primary">{{
            hasCount
          }}</sup>
        </div>
        <i class="ml-2" *ngIf="rightIcon" [class]="rightIcon"> </i>
      </button>
    </ng-container>
  </div>
</ng-template>
<div *ngIf="isMenuButton; else button" [ngSwitch]="type">
  <ng-container *ngSwitchCase="'basic'">
    <button
      class="el-button"
      [ngClass]="getClass()"
      [style.width]="width"
      type="button"
      [disabled]="disabled"
      [matMenuTriggerFor]="menu"
    >
      <i class="mr-2" *ngIf="leftIcon" [class]="leftIcon"></i>
      <img class="mr-2" *ngIf="leftSvg" [src]="leftSvg" alt="" />
      <span class="btn-text">{{ name }}</span>
      <i class="ml-2" *ngIf="rightIcon" [class]="rightIcon"> </i>
    </button>
    <mat-menu class="btn-menu" #menu="matMenu" [xPosition]="xPosition">
      <button
        [ngClass]="menuBtnColor"
        (click)="onMenuOptionSelected(option.value)"
        *ngFor="let option of menuOptions"
        mat-menu-item
        [disabled]="option?.isDisabled"
      >
        <ng-container *ngIf="option?.iconName; else svgIcon">
          <i [class]="option.iconName"></i>
        </ng-container>
        <ng-template #svgIcon>
          <img
            class="svg-icon"
            *ngIf="option?.iconSvgPath"
            [src]="option.iconSvgPath"
            alt="icon"
          />
        </ng-template>
        <span>{{ option?.name }}</span>
      </button>
    </mat-menu>
  </ng-container>
</div>
