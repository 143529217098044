import {
  Directive,
  ElementRef,
  AfterViewChecked,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[simplifiStickyCreatePlanNav]',
})
export class StickyCreatePlanNavDirective implements AfterViewChecked {
  private breadcrumbContainer!: HTMLElement;

  constructor(private el: ElementRef) {}

  ngAfterViewChecked() {
    this.setStickyPosition();
  }

  @HostListener('window:resize')
  onResize() {
    this.setStickyPosition();
  }

  private setStickyPosition() {
    this.breadcrumbContainer = document.querySelector(
      '.breadcrumb-container',
    ) as HTMLElement;
    if (!this.breadcrumbContainer) {
      return;
    }

    const breadcrumbHeight =
      this.breadcrumbContainer.getBoundingClientRect().height;
    this.el.nativeElement.style.position = 'sticky';
    this.el.nativeElement.style.top = `${breadcrumbHeight}px`;
  }
}
