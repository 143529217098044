import {Injectable} from '@angular/core';
import {
  DeletePlanVersionZtvZipCodeKeywords,
  GetPlanVersionLocationZipcodeForZtv,
  GetPlanVersionZtv,
  GetZtvZipCodes,
} from '../commands';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {Observable} from 'rxjs';
import {ZtvZipCodesAdapter} from '../adapters';
import {Words} from '../interfaces';
import {HttpParams} from '@angular/common/http';
import {UserSessionStoreService} from '@simplifi/core/store';
import {AuthService} from '@simplifi/core/auth';

/**
 * Service to handle operations related to ZTV (Zip Code Targeting) and its associated zip codes.
 */
@Injectable()
export class ZtvZipCodesFacadeService {
  private tenantId: string;

  /**
   * Constructs an instance of `ZtvZipCodesFacadeService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {AnyAdapter} anyAdapter - The adapter for handling API responses.
   * @param {ZtvZipCodesAdapter} ztvZipCodesAdapter - The specific adapter for ZTV zip codes.
   * @param {UserSessionStoreService} store - The user session store service.
   * @param {AuthService} authService - The authentication service.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly ztvZipCodesAdapter: ZtvZipCodesAdapter,
    private readonly store: UserSessionStoreService,
    private readonly authService: AuthService,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  /**
   * Retrieves ZTV zip codes based on user input.
   *
   * @param {string} body - The comma-separated zip codes input by the user.
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the retrieved zip codes.
   */
  getZtvZipCodes(
    body: string,
    planId: string,
    versionId: string,
  ): Observable<Words[]> {
    const command: GetZtvZipCodes<Words[]> = new GetZtvZipCodes(
      this.apiService,
      this.ztvZipCodesAdapter,
      this.tenantId,
      planId,
      versionId,
    );
    body = body.replace(/,\s*$/, '');
    command.parameters = {
      data: {
        userInput: body.split(/, ?/),
        stateSessionId: this.store.getStateSession(),
      },
    };
    return command.execute();
  }

  /**
   * Retrieves zip codes for a given plan version based on its location.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} planVersionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the retrieved location zip codes.
   */
  getPlanVersionLocationZipCodes(
    planId: string,
    planVersionId: string,
  ): Observable<Words[]> {
    return new Observable(subscriber => {
      const command: GetPlanVersionLocationZipcodeForZtv<Words[]> =
        new GetPlanVersionLocationZipcodeForZtv(
          this.apiService,
          this.anyAdapter,
          this.store,
          this.authService,
          this.tenantId,
          planId,
          planVersionId,
          subscriber,
        );
      if (this.store.getStateSession()) {
        command.parameters = {
          query: new HttpParams().set(
            'stateSessionId',
            this.store.getStateSession(),
          ),
        };
      }

      command.execute();
      return () => {};
    });
  }

  /**
   * Deletes specified ZTV zip codes for a given plan version.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   * @param {Words} zipCode - The zip code to delete.
   *
   * @returns {Observable<void>} - An observable that completes when the deletion is done.
   */
  deleteZtvZipCodes(versionId: string, planId: string, zipCode: Words) {
    const command: DeletePlanVersionZtvZipCodeKeywords<Words> =
      new DeletePlanVersionZtvZipCodeKeywords(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        versionId,
      );
    command.parameters = {};
    if (zipCode?.id) {
      command.parameters['data'] = {
        ztvZipCodeId: zipCode.id,
        stateSessionId: this.store.getStateSession(),
      };
    } else {
      command.parameters['data'] = {
        stateSessionId: this.store.getStateSession(),
      };
      command.parameters['query'] = new HttpParams().set('deleteAll', true);
    }
    return command.execute();
  }

  /**
   * Retrieves ZTV zip codes for a given plan version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the retrieved ZTV zip codes.
   */
  getPlanVersionZtvZipCodes(
    planId: string,
    versionId: string,
  ): Observable<Words[]> {
    const command: GetPlanVersionZtv<Words[]> = new GetPlanVersionZtv(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      planId,
      versionId,
    );
    if (this.store.getStateSession()) {
      command.parameters = {
        query: new HttpParams().set(
          'stateSessionId',
          this.store.getStateSession(),
        ),
      };
    }
    return command.execute();
  }
}
