import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {ZtvZipCodes} from '../models';
import {Words} from '../interfaces';

@Injectable()
export class ZtvZipCodesAdapter implements IAdapter<Words[]> {
  /**
   * The function `adaptToModel` takes a response object and converts it into a ZtvZipCodes object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `ZtvZipCodes` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(items: any): Words[] {
    const ztvZipCodes: Words[] = [];
    if (items.length) {
      items.map((item: {id: string; zipCode: string}) => {
        const ztvZipCode: Words = {id: '', name: ''};
        ztvZipCode.id = item.id;
        ztvZipCode.name = item.zipCode;
        ztvZipCodes.push(ztvZipCode);
      });
    }
    return ztvZipCodes;
  }

  /**
   * The function `adaptFromModel` takes a `ZtvZipCodes` object and returns a modified version of it with
   * specific properties and values.
   * @param {ZtvZipCodes} data - The `data` parameter is an object of type `ZtvZipCodes` which contains various
   * properties such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptFromModel` returns an object with the following properties:
   */
  adaptFromModel(data: ZtvZipCodes[]): any {
    return data;
  }
}
