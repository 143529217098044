<div class="dialog-main-wrapper keywords-dialog">
  <ngx-ui-loader
    [loaderId]="loaderId"
    [fgsTemplate]="foregroundSpinner"
  ></ngx-ui-loader>
  <ng-template #foregroundSpinner>
    <simplifi-page-loader [diameter]="30"></simplifi-page-loader>
  </ng-template>
  <div mat-dialog-title class="el-dialog__header">
    <span class="el-dialog__title"> AI Plan Data </span>
    <button
      type="button"
      aria-label="Close"
      class="el-dialog__headerbtn"
      mat-dialog-close
    >
      <i class="el-dialog__close el-icon el-icon-close"></i>
    </button>
  </div>
  <mat-dialog-content>
    <div class="keywords-list-wrapper">
      <div class="keywords-list" *ngIf="chatData.length > 0">
        <mat-accordion *ngFor="let data of chatData">
          <mat-expansion-panel
            class="mat-elevation-z0"
            expanded="true"
            *ngIf="data.values?.length"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ data.values.length }} {{ data?.name }}(s)
              </mat-panel-title>
              <span
                class="el-icon-arrow-right acc-arrow pointer-events-all ml-2"
              ></span>
            </mat-expansion-panel-header>
            <div class="keywords-container">
              <div class="pill-items flex flex-wrap gap-1">
                <div
                  class="pill rounded-full"
                  *ngFor="let value of data?.values"
                >
                  <div class="truncate" [matTooltip]="value">{{ value }}</div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div
        *ngIf="chatData.length === 0 && !isLoading"
        class="no-chat-data flex-1 flex flex-col items-center justify-center"
      >
        <img
          class="not-found"
          [src]="noChatDataIcon"
          alt="chat data not found"
        />
        <div class="text-secondary font-xl mt-4">Chat data not found</div>
      </div>
    </div>
  </mat-dialog-content>
</div>
