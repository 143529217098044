import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[simplifiStickyBudget]',
})
export class StickyBudgetDirective implements AfterViewInit, OnDestroy {
  private budgetAllocationElement!: HTMLElement;
  private mainContainerElement!: HTMLElement;
  private forStickyElement!: HTMLElement;
  private initialLeft!: number;
  private originalWidth!: string;
  private stickyTopPosition = 20;
  private mutationObserver!: MutationObserver;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    this.initElements();
    this.setupScrollListener();
    this.setupMutationObserver();
    this.checkSticky();
  }

  private initElements() {
    this.budgetAllocationElement = this.elementRef.nativeElement;

    this.mainContainerElement = document.querySelector(
      '.main-container',
    ) as HTMLElement;
    this.forStickyElement = document.querySelector(
      '#for-sticky',
    ) as HTMLElement;

    if (
      !this.budgetAllocationElement ||
      !this.mainContainerElement ||
      !this.forStickyElement
    ) {
      return;
    }

    this.originalWidth = window.getComputedStyle(
      this.budgetAllocationElement,
    ).width;
    this.initialLeft =
      this.budgetAllocationElement.getBoundingClientRect().left;
  }

  private setupScrollListener() {
    const mainContainer = this.mainContainerElement;

    if (mainContainer) {
      mainContainer.addEventListener('scroll', () => this.checkSticky());
      mainContainer.addEventListener('resize', () => this.checkSticky());
    }
  }

  private setupMutationObserver() {
    const targetNode = document.querySelector('.budget-tab-group');
    if (targetNode) {
      this.mutationObserver = new MutationObserver(() => {
        this.initElements();
        this.checkSticky();
      });

      this.mutationObserver.observe(targetNode, {
        childList: true,
        subtree: true,
      });
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.checkSticky();
  }

  private checkSticky() {
    if (
      !this.budgetAllocationElement ||
      !this.mainContainerElement ||
      !this.forStickyElement
    ) {
      return;
    }

    const breadcrumbBottom = document
      .querySelector('.breadcrumb-container')
      ?.getBoundingClientRect().bottom;

    const forStickyTop = this.forStickyElement.getBoundingClientRect().top;

    if (!breadcrumbBottom) {
      return;
    }

    const isSticky = forStickyTop <= breadcrumbBottom;
    if (isSticky) {
      const forStickyWidth =
        this.forStickyElement.getBoundingClientRect().width;
      const forStickyLeft = this.forStickyElement.getBoundingClientRect().left;

      this.renderer.setStyle(this.budgetAllocationElement, 'position', 'fixed');
      this.renderer.setStyle(
        this.budgetAllocationElement,
        'top',
        `${breadcrumbBottom}px`,
      );
      this.renderer.setStyle(
        this.budgetAllocationElement,
        'left',
        `${forStickyLeft}px`,
      );
      this.renderer.setStyle(
        this.budgetAllocationElement,
        'width',
        `${forStickyWidth}px`,
      );
      this.renderer.setStyle(this.budgetAllocationElement, 'z-index', '1');
      this.renderer.addClass(
        this.budgetAllocationElement,
        'show-sticky-budget',
      );
    } else {
      this.renderer.removeStyle(this.budgetAllocationElement, 'position');
      this.renderer.removeStyle(this.budgetAllocationElement, 'top');
      this.renderer.removeStyle(this.budgetAllocationElement, 'left');
      this.renderer.removeStyle(this.budgetAllocationElement, 'width');
      this.renderer.removeStyle(this.budgetAllocationElement, 'z-index');
      this.renderer.removeClass(
        this.budgetAllocationElement,
        'show-sticky-budget',
      );
    }
  }

  ngOnDestroy() {
    // Cleanup MutationObserver
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }
}
