import {PlanConfigKeys} from '@simplifi/main/profile/constants';
import {FrequencyConfig} from './frequency-config.model';
import {TacticsFrequencyConfig} from './tactics-frequency-config.model';

export class PlanConfiguration {
  id?: string;
  configKey?: PlanConfigKeys;
  frequencyConfig!: FrequencyConfig;
  tacticsConfig!: TacticsFrequencyConfig;
  constructor() {
    this.configKey = PlanConfigKeys.BUDGET_FREQUENCY_CONFIG;
    this.frequencyConfig = new FrequencyConfig();
    this.tacticsConfig = new TacticsFrequencyConfig();
  }
}
