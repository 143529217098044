import {ChatMetadata} from './chat-metadata.model';
import {Message} from './message.model';

export class Chats {
  message: Message;
  id!: string;
  date!: string;
  enableAi!: boolean;
  tagEnabled!: boolean;
  banner!: {
    color: string;
    message: string;
  };
  occupants!: number;
  enablePlanCreation = false;
  metadata: ChatMetadata;
  constructor(id: string) {
    this.id = id;
    this.message = new Message();
    this.metadata = new ChatMetadata();
  }
}

export class ChannelCount {
  count!: number;
}
