import {LocationType, PlanStatus} from '@simplifi/shared/enums';
import {ContentCategory} from './content-categories.model';
import {Words} from '../interfaces';
import {PlanResultView} from '@simplifi/shared/models';
import {IGridColumn} from '@simplifi/shared/modules';
import {PlanResultZtvSummaryData} from './plan-result-ztv-summary-data.model';
import {Budget} from './budget.model';
import {BudgetTypes} from './budget-types.model';
import {Result} from './result.model';
import {DeviceType} from './device-type.model';
export class Plan {
  id!: string;
  currentVersion!: string;
  name?: string;
  status: PlanStatus = PlanStatus.Draft;
  startDate?: Date;
  endDate?: Date;
  advertiserUrl?: string;
  campaignGoalId?: string;
  campaignGoalName?: string;
  budgetType?: string;
  locationType!: LocationType;
  locations?: Words[];
  contentCategories?: string[];
  contentCategoriesName: ContentCategory[] = [];
  deviceTypes: DeviceType[] = [];
  keyword!: Suggestions;
  ztv!: Suggestions;
  contextual!: Suggestions;
  addressable!: Suggestions;
  addressableIds: string[] = [];
  hasKeywords!: boolean;
  hasAddressable!: boolean;
  hasContextual!: boolean;
  hasZtv!: boolean;
  versionTag?: string;
  isCurrentVersion = true;
  disabled = false;
  budget!: BudgetTypes;
  suggestedBudget!: Budget;
  result: Result;
  constructor() {
    this.keyword = new Suggestions();
    this.ztv = new Suggestions();
    this.contextual = new Suggestions();
    this.addressable = new Suggestions();
    this.contentCategories = [];
    this.disabled =
      !this.isCurrentVersion || this.status === PlanStatus.Downloaded;
    this.budget = new BudgetTypes();
    this.result = new Result();
    this.suggestedBudget = new Budget();
  }
}

export class Suggestions {
  enabled = false;
  words: Words[] = [];
}
export class PlansVersionLocation {
  type!: LocationType;
  data!: object;
}

export class PlansGeneralSectionForm {
  isValid = false;
  isDirty = false;
  checkTargets?: boolean = false;
}

export class PlansResultExport {
  title = '';
  colDefs: IGridColumn[] = [];
  rowData: (PlanResultZtvSummaryData | PlanResultView)[] = [];
}
