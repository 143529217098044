export class TacticsMediaFrequencyConfig {
  display!: number;
  video!: number;
  ottCtv!: number;
  constructor() {
    this.display = 0;
    this.video = 0;
    this.ottCtv = 0;
  }
}
