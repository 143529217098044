export class FrequencyConfig {
  peopleReach!: number;
  houseHold!: number;
  budgetCalc!: number;

  constructor() {
    this.peopleReach = 0;
    this.houseHold = 0;
    this.budgetCalc = 0;
  }
}
