import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Words} from '../interfaces';

@Injectable()
export class ContextualAdapter implements IAdapter<Words[]> {
  /**
   * The function `adaptToModel` takes a response object and converts it into a Words object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `Words` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */
  adaptToModel(items: any): Words[] {
    const contextualKeywords: Words[] = [];
    if (items.length) {
      items.map(
        (item: {
          parentname: any;
          id: string;
          name: string;
          parentId: string;
          parentid: string;
        }) => {
          const parent = item.parentname;
          if (parent) {
            const contextualKeyword: Words = {id: '', name: '', parentId: ''};
            contextualKeyword.id = item.id;
            contextualKeyword.name = item.name;
            contextualKeyword.groupName = parent ?? 'Others';
            contextualKeyword.parentId = item.parentId ?? item.parentid;
            contextualKeywords.push(contextualKeyword);
          }
        },
      );
    }
    return contextualKeywords;
  }

  adaptFromModel(data: Words[]): any {
    return data;
  }
}
