import {Injectable} from '@angular/core';
import {TileKeysInterface} from '../constants';
import moment from 'moment';
import {CreatePlanService} from '.';
import {PlanResultSummary} from '../models';
@Injectable()
export class PlanResultService {
  plan = this.createPlanService.plan;
  constructor(private createPlanService: CreatePlanService) {
    /**Data Being Used For Tile Regarding Plan Flight Date */
    this.createPlanService.planData$.subscribe(data => {
      this.plan = data;
    });
  }

  /**
   * The function `formTilesData` updates various tile texts based on input data and returns the
   * updated resultTiles object.
   * @param resultTiles - The `formTilesData` function takes in three parameters:
   * @param {PlanResultSummary} resultSummary - The `resultSummary` parameter in the `formTilesData`
   * function is of type `PlanResultSummary`. It contains information such as total households, total
   * populations, and location count related to a plan result.
   * @param {string} blendedCpm - Blended CPM refers to the combined cost per thousand impressions for
   * a campaign that includes multiple types of media or advertising channels. In the provided function
   * `formTilesData`, the `blendedCpm` parameter is a string value that is used to update the
   * `blendedCpm` tile text
   * @returns The function `formTilesData` is returning the `resultTiles` object after updating the
   * `tileText` values for various keys such as 'flightDate', 'blendedCpm', 'householdReach',
   * 'populationReach', and 'locationCount'.
   */
  formTilesData(
    resultTiles: Record<string, TileKeysInterface>,
    resultSummary: PlanResultSummary,
    blendedCpm: string,
  ) {
    resultTiles['flightDate'].tileText =
      `${moment(this.plan.startDate).format('MM/DD/YYYY')}-${moment(this.plan.endDate).format('MM/DD/YYYY')}`;
    resultTiles['blendedCpm'].tileText = blendedCpm;
    resultTiles['householdReach'].tileText = String(
      resultSummary.totalHouseholds,
    );
    resultTiles['populationReach'].tileText = String(
      resultSummary.totalPopulations,
    );
    resultTiles['locationCount'].tileText = String(resultSummary.locationCount);
    return resultTiles;
  }
}
