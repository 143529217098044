import {Injectable} from '@angular/core';
import {MediaType} from '../models';
import {CreatePlanService} from './create-plan.service';
import {TargetMediaType} from '../models/target-media-type.model';
import {PlanTarget} from '@simplifi/shared/enums';

@Injectable()
export class PlanBudgetService {
  constructor(public createPlanService: CreatePlanService) {}

  /**
   * Allocates the budget for a specific media type based on the percentage allocation.
   *
   * This method calculates and assigns the budget for the provided `mediaType`
   * by applying its allocated budget percentage to the total plan budget.
   *
   * @param {MediaType} mediaType - The media type for which the budget is being allocated.
   * @param {number} planBudget - The total budget of the plan.
   *
   * @returns {void}
   */
  allocateBudgetByPercentage(mediaType: MediaType, planBudget: number): void {
    mediaType.allocatedBudget = Math.round(
      (planBudget * +mediaType.allocatedBudgetPercent) / 100,
    );
  }

  /**
   * Calculates the percentage of the budget allocated to a specific media type.
   *
   * This method calculates the percentage of the total plan budget that has been
   * allocated to the provided `mediaType` and updates the `allocatedBudgetPercent` property.
   *
   * @param {MediaType} mediaType - The media type for which the budget percentage is being calculated.
   * @param {number} planBudget - The total budget of the plan.
   *
   * @returns {void}
   */
  calculateBudgetPercentage(mediaType: MediaType, planBudget: number): void {
    mediaType.allocatedBudgetPercent = Math.round(
      (+mediaType.allocatedBudget / planBudget) * 100,
    );
  }

  /**
   * Sets the width of the budget graph based on the budget allocation for different target media types.
   *
   * This method calculates and returns a map of target types to their respective widths
   * in the budget allocation graph. The width is expressed as a percentage of the total budget.
   *
   * @param {TargetMediaType[]} budgetAllocation - An array of target media types with their respective budgets.
   * @param {number} totalBudget - The total budget allocated to all media types.
   *
   * @returns {Record<PlanTarget, string>} - A map of `PlanTarget` keys to their corresponding width percentages as strings.
   */
  setBudgetGraphWidth(
    budgetAllocation: TargetMediaType[],
    totalBudget: number,
  ): Record<PlanTarget, string> {
    const targetWidthMap: Record<PlanTarget, string> = {
      [PlanTarget.Addressable]: '',
      [PlanTarget.Contextual]: '',
      [PlanTarget.keyword]: '',
      [PlanTarget.Ztv]: '',
    };
    budgetAllocation.forEach(mapping => {
      targetWidthMap[mapping.targetType] = this.calculateWidth(
        totalBudget,
        mapping.targetBudget,
      );
    });
    return targetWidthMap;
  }

  /**
   * Calculates the width percentage for a budget allocation graph.
   *
   * This method returns the width percentage for a target type's budget allocation
   * in relation to the total budget, formatted as a string with a percentage sign.
   *
   * @param {number} totalBudget - The total budget allocated to all media types.
   * @param {number} [targetBudget=0] - The budget allocated to a specific target type.
   *
   * @returns {string} - The calculated width percentage as a string.
   */
  calculateWidth(totalBudget: number, targetBudget = 0): string {
    return `${(targetBudget / totalBudget) * 100}%`;
  }
}
