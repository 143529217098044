import {ApiService, DelAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class DeletePlanContextualKeywords<T> extends DelAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    planId: string,
    versionId: string,
    tenantId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${versionId}/contextual-segments`,
    );
  }
}
