import {ApiService, GetListAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';

export class GetChatById<T> extends GetListAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId: string,
    channelId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.chatFacadeApiUrl}/tenants/${tenantId}/channels/${channelId}/messages`,
    );
  }
}
