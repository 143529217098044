<div class="dialog-main-wrapper">
  <div mat-dialog-title class="el-dialog__header">
    <span class="el-dialog__title">
      {{ data.confirmationTitle }}
    </span>
    <button
      type="button"
      aria-label="Close"
      class="el-dialog__headerbtn"
      (click)="closeDialog()"
    >
      <i class="el-dialog__close el-icon el-icon-close"></i>
    </button>
  </div>
  <mat-dialog-content>
    <div class="confirmation-msg text-primary fw-normal mb-4">
      {{ data.confirmationMessage }}
    </div>
    <div *ngIf="data?.showCheckbox" class="delete-all">
      <div class="simpli-checkbox cursor-pointer">
        <mat-checkbox
          class="simpli-checkbox text-primary"
          disableRipple="false"
          [checked]="confirmAction"
          (change)="confirmDelete($event)"
        >
          {{ data.checkBoxText }}
        </mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    class="form-action el-row--flex is-justify-space-between gap-x-2"
  >
    <div class="form-lhs-action text-secondary">
      {{ data.totalRecord }} {{ data.recordText }}
    </div>
    <div class="form-rhs-action el-row--flex is-justify-end gap-x-2">
      <simplifi-input-button
        *ngIf="!data?.hideCancelButton"
        [mat-dialog-close]="true"
        color="defaultText"
        [name]="data?.actionNoLabel ?? 'Cancel'"
        (click)="onClick(false)"
      ></simplifi-input-button>
      <simplifi-input-button
        *ngIf="!data?.hideConfirmButton"
        type="basic"
        color="danger"
        [name]="data?.actionYesLabel ?? 'Delete'"
        (click)="onClick(true)"
        [disabled]="isDisabled"
      ></simplifi-input-button>
    </div>
  </mat-dialog-actions>
</div>
