import {Injectable} from '@angular/core';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {
  CreateStateSessionCommand,
  StateManagementCommand,
  StateSessionPresenceCommand,
} from '../commands';
import {STATUS_CODE, UserSessionStoreService} from '@simplifi/core/index';
import {catchError, map} from 'rxjs';
import {StateManagement} from '../models';

/**
 * Service for managing state sessions, including creation, presence checks, and state updates.
 */
@Injectable()
export class StateSessionService {
  /**
   * Constructs an instance of `StateSessionService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {AnyAdapter} anyAdapter - The adapter for handling API responses.
   * @param {UserSessionStoreService} store - The user session store service.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly store: UserSessionStoreService,
  ) {}

  /**
   * Creates or removes a state session based on the provided session ID.
   *
   * @param {string} [sessionId] - The ID of the session to create or remove. If not provided, a new session will be created.
   *
   * @returns {Observable<void>} - An observable that completes when the state session is created or removed.
   */
  createRemoveStateSession(sessionId?: string) {
    const command: CreateStateSessionCommand<{id: string}> =
      new CreateStateSessionCommand(
        this.apiService,
        this.anyAdapter,
        this.store.getUser().defaultTenantId,
        this.store.getUser().id,
      );
    command.parameters = {
      data: {id: sessionId},
    };
    return command.execute().pipe(
      map(res => {
        this.store.saveStateSession(res?.id);
      }),
    );
  }

  /**
   * Checks the presence of a state session based on the provided session ID.
   *
   * @param {string} sessionId - The ID of the session to check.
   *
   * @returns {Observable<object>} - An observable that emits the result of the session presence check.
   */
  stateSessionPresence(sessionId: string) {
    const command: StateSessionPresenceCommand<object> =
      new StateSessionPresenceCommand(
        this.apiService,
        this.anyAdapter,
        this.store.getUser().defaultTenantId,
        this.store.getUser().id,
        sessionId,
      );
    return command.execute().pipe(
      catchError(err => {
        if (err.error.error.statusCode === STATUS_CODE.NOT_FOUND) {
          window.location.reload();
        }
        return err;
      }),
    );
  }

  /**
   * Updates the state of a plan version with the given data.
   *
   * @param {StateManagement} data - The data to update the state with.
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<void>} - An observable that completes when the state update is done.
   */
  updateState(data: StateManagement, planId: string, versionId: string) {
    const command: StateManagementCommand<object> = new StateManagementCommand(
      this.apiService,
      this.anyAdapter,
      this.store.getUser().defaultTenantId,
      planId,
      versionId,
    );
    command.parameters = {
      data,
    };
    return command.execute();
  }
}
