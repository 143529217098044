import {ApiService, IAdapter, PostAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';
export class AddBulkBudgetMediaTypesCommand<T> extends PostAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    planId: string,
    versionId: string,
    strategy: PlanBudgetStrategy,
    tenantId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${versionId}/budget-media-types/${strategy}`,
    );
  }
}
