<form #form1="ngForm">
  <div class="pagination-container">
    <div class="total-count">{{ totalRecords }} items</div>
    <div class="pagination-select">
      <simplifi-select-box
        [sort]="false"
        [items]="paginationValues"
        [(selectModel)]="recordsPerPage"
        [inputLabel]="false"
        (selectModelChange)="onRecordsPerPageSelectionChange($event)"
      ></simplifi-select-box>
    </div>
    <div class="pagination">
      <button
        *ngIf="totalRecords"
        [disabled]="currentPage === 1"
        (click)="onPageSelect(currentPage - 1)"
      >
        <i class="el-icon-arrow-left"></i>
      </button>
      <div>
        <button
          *ngFor="let page of getPageNumbers()"
          [class.active]="page === currentPage"
          (click)="onPageSelect(page)"
          [disabled]="page === -1"
          [ngClass]="{ellipsis: page === -1, disabled: page === -1}"
        >
          {{ page === -1 ? '...' : page }}
        </button>
      </div>

      <button
        *ngIf="totalRecords"
        [disabled]="currentPage === totalPages"
        (click)="onPageSelect(currentPage + 1)"
      >
        <i class="el-icon-arrow-right"></i>
      </button>
    </div>
  </div>
</form>
