import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {UserSessionStoreService} from '@simplifi/core/index';
import {Message} from '../models';
import {
  ChannelMessageType,
  chatMessageAction,
  SenderName,
} from '@simplifi/shared/enums';

@Injectable()
export class ChatMessageAdapter implements IAdapter<Message> {
  constructor(private readonly store: UserSessionStoreService) {}
  adaptToModel(resp: any): Message {
    let type = resp?.parentId
      ? ChannelMessageType.Quote
      : ChannelMessageType.Text;
    let customMessageData = {};
    let text = resp.body;
    let customAction = '';
    if (resp?.metadata?.link) {
      type = ChannelMessageType.customMessage;
      const planLinkMessage = resp?.body.replace('{{ link }}', '');
      text = '';
      customMessageData = {
        params: resp?.metadata?.link,
        planName: resp?.metadata?.planName,
        body: planLinkMessage,
      };
    }
    if (resp?.metadata?.hasMore) {
      customAction = chatMessageAction.ViewMore;
    }
    const senderName =
      resp.sender === this.store.getUser().userTenantId
        ? SenderName.You
        : resp.userDetails?.name;
    const message = new Message();
    message.id = resp.id;
    message.type = type;
    message.parentId = resp?.parentId;
    message.text = text;
    message.reply =
      resp.sender === this.store.getUser().userTenantId &&
      !resp.parentId &&
      !resp.systemGenerated;
    message.date = new Date(resp.modifiedOn);
    message.user = {
      name:
        resp?.parentId || resp.systemGenerated ? SenderName.AiBot : senderName,
      id: resp.sender,
      avatar: resp?.systemGenerated
        ? '/assets/images/campaign-plan/spur-icon.png'
        : '',
    };
    message.systemGenerated = resp.systemGenerated;
    message.customMessageData = customMessageData;
    message.aiResponse = false;
    message.customAction = customAction;
    return message;
  }
  adaptFromModel(data: Message) {
    return data;
  }

  getSubstring(str: string, start: string, end: string) {
    const char1 = str.indexOf(start) + 1;
    const char2 = str.lastIndexOf(end);
    return str.substring(char1, char2);
  }
}
