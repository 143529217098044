import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
} from '@angular/core';
import {IToaster, TOASTER_SERVICE_KEY} from '@simplifi/core/toaster';

@Directive({
  selector: '[simplifiMinValue]',
})
export class MinValueDirective {
  @Input('simplifiMinValue') minValue!: number;

  constructor(
    private el: ElementRef,
    @Inject(TOASTER_SERVICE_KEY)
    private readonly toaster: IToaster,
  ) {}

  @HostListener('focusout', ['$event']) onFocusOut(event: Event) {
    if (!this.minValue) {
      return;
    }
    const inputElement = event.target as HTMLInputElement;
    let inputValue = inputElement.value;

    let inputNumber = Number(inputValue);

    if (
      isNaN(inputNumber) ||
      inputNumber < this.minValue ||
      inputValue === ''
    ) {
      this.toaster.warn(
        `Max bid value cannot be lower than min recommended value(${this.minValue})`,
        'Invalid value',
      );
      inputElement.value = this.minValue?.toString();
      event.stopPropagation();
    }
  }
}
