import {Injectable} from '@angular/core';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {Observable} from 'rxjs';
import {City, Metro, PlansVersionLocation, State, Zipcode} from '../models';
import {HttpParams} from '@angular/common/http';
import {
  GetPlanVersionCitiesCommand,
  GetPlanVersionMetrosCommand,
  GetPlanVersionStatesCommand,
  GetPlanVersionLocationZipcodesCommand,
  PostBulkLocationsCommand,
  SavePlanLocations,
  DeletePlanVersionLocations,
  GetPlanVersionLocations,
} from '../commands';
import {UserSessionStoreService} from '@simplifi/core/store';
import {IAnyObject} from '@simplifi/core/i-any-object';
import {AuthService} from '@simplifi/core/auth';
import {LocationType} from '@simplifi/shared/enums';
import {Words} from '../interfaces';

/**
 * Service for managing locations associated with a plan version, including retrieval, creation, update, and deletion.
 */
@Injectable()
export class LocationFacadeService {
  /**
   * The tenant ID associated with the user.
   */
  tenantId: string;

  /**
   * Constructs an instance of `LocationFacadeService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {AnyAdapter} anyAdapter - The adapter for handling API responses.
   * @param {UserSessionStoreService} store - The user session store service.
   * @param {AuthService} authService - The authentication service for handling authorization.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly store: UserSessionStoreService,
    private readonly authService: AuthService,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  /**
   * Retrieves a list of states for a specific plan version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} planVersionId - The ID of the plan version.
   *
   * @returns {Observable<State[]>} - An observable that emits the list of states.
   */
  getStatesForPlanVersion(
    planId: string,
    planVersionId: string,
  ): Observable<State[]> {
    const command: GetPlanVersionStatesCommand<State[]> =
      new GetPlanVersionStatesCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        planVersionId,
      );
    return command.execute();
  }

  /**
   * Retrieves a list of cities for a specific plan version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} planVersionId - The ID of the plan version.
   *
   * @returns {Observable<City[]>} - An observable that emits the list of cities.
   */
  getCitiesForPlanVersion(
    planId: string,
    planVersionId: string,
  ): Observable<City[]> {
    const command: GetPlanVersionCitiesCommand<City[]> =
      new GetPlanVersionCitiesCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        planVersionId,
      );
    if (this.store.getStateSession()) {
      command.parameters = {
        query: new HttpParams().set(
          'stateSessionId',
          this.store.getStateSession(),
        ),
      };
    }

    return command.execute();
  }

  /**
   * Retrieves a list of metros for a specific plan version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} planVersionId - The ID of the plan version.
   *
   * @returns {Observable<Metro[]>} - An observable that emits the list of metros.
   */
  getMetrosForPlanVersion(
    planId: string,
    planVersionId: string,
  ): Observable<Metro[]> {
    const command: GetPlanVersionMetrosCommand<Metro> =
      new GetPlanVersionMetrosCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        planVersionId,
      );
    if (this.store.getStateSession()) {
      command.parameters = {
        query: new HttpParams().set(
          'stateSessionId',
          this.store.getStateSession(),
        ),
      };
    }
    return command.execute();
  }

  /**
   * Retrieves a list of zip codes for a specific plan version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} planVersionId - The ID of the plan version.
   *
   * @returns {Observable<Zipcode[]>} - An observable that emits the list of zip codes.
   */
  getZipcodesForPlanVersion(
    planId: string,
    planVersionId: string,
  ): Observable<Zipcode[]> {
    return new Observable(subscriber => {
      const command: GetPlanVersionLocationZipcodesCommand<IAnyObject> =
        new GetPlanVersionLocationZipcodesCommand(
          this.apiService,
          this.anyAdapter,
          this.store,
          this.authService,
          this.tenantId,
          planId,
          planVersionId,
          subscriber,
        );
      if (this.store.getStateSession()) {
        command.parameters = {
          query: new HttpParams().set(
            'stateSessionId',
            this.store.getStateSession(),
          ),
        };
      }

      command.execute();
      return () => {};
    });
  }

  /**
   * Creates bulk locations for the plan version.
   *
   * @param {State[]} locations - The list of locations to create.
   *
   * @returns {Observable<State[]>} - An observable that emits the created locations.
   */
  createBulkLocations(locations: State[]): Observable<State[]> {
    const command: PostBulkLocationsCommand<State[]> =
      new PostBulkLocationsCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
      );
    command.parameters = {
      data: locations,
    };
    return command.execute();
  }

  /**
   * Adds or edits locations for a specific plan version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   * @param {LocationType} locationType - The type of the location.
   *
   * @returns {Observable<void>} - An observable that completes when the locations are added or edited.
   */
  addEditLocations(
    planId: string,
    versionId: string,
    locationType: LocationType,
  ) {
    const command: SavePlanLocations<PlansVersionLocation> =
      new SavePlanLocations(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        versionId,
      );

    command.parameters = {
      data: {
        stateSessionId: this.store.getStateSession(),
        type: locationType,
      },
    };

    return command.execute();
  }

  /**
   * Deletes locations for a specific plan version.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   * @param {Words} location - The location to delete.
   * @param {LocationType} locationType - The type of the location.
   *
   * @returns {Observable<void>} - An observable that completes when the locations are deleted.
   */
  deletePlanLocations(
    versionId: string,
    planId: string,
    location: Words,
    locationType: LocationType,
  ) {
    const command: DeletePlanVersionLocations<Words> =
      new DeletePlanVersionLocations(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        versionId,
      );
    command.parameters = {};
    if (location?.id) {
      command.parameters['data'] = {
        locationId: location.id,
        stateSessionId: this.store.getStateSession(),
        type: locationType,
      };
    } else {
      command.parameters['data'] = {
        stateSessionId: this.store.getStateSession(),
        type: locationType,
      };
      command.parameters['query'] = new HttpParams().set('deleteAll', true);
    }
    return command.execute();
  }

  /**
   * Retrieves a list of locations for a specific plan version.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   *
   * @returns {Observable<Words[]>} - An observable that emits the list of locations.
   */
  getPlanVersionLocations(
    versionId: string,
    planId: string,
  ): Observable<Words[]> {
    const command = new GetPlanVersionLocations(
      this.apiService,
      this.anyAdapter,
      planId,
      versionId,
      this.tenantId,
    );

    return command.execute();
  }
}
