<div
  *ngIf="isOpen"
  class="custom-overlay"
  [ngStyle]="{
    left: position === 'left' ? '0' : 'auto',
    right: position === 'right' ? '0' : 'auto',
  }"
>
  <!-- Your overlay content goes here -->
  <div class="overlay-content">
    <ng-content></ng-content>
  </div>
</div>
