import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[simplifiCustomTooltip]',
})
export class CustomTooltipDirective {
  // sonarignore:start
  @Input('simplifiCustomTooltip') simplifiCustomTooltip?: TemplateRef<any>;

  private tooltipElement: HTMLElement | null = null;
  private hideTimeout: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
  ) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    if (!this.isCursorOverTriggerOrTooltip(event)) {
      if (this.hideTimeout) {
        clearTimeout(this.hideTimeout);
      }
      this.hideTimeout = setTimeout(() => {
        this.hideTooltip();
      }, 2000);
    }
  }

  private showTooltip() {
    if (!this.tooltipElement && this.simplifiCustomTooltip) {
      const mainContainer = document.querySelector('.main-container');
      if (mainContainer) {
        this.renderer.setStyle(mainContainer, 'overflow', 'hidden');
      }
      this.tooltipElement = this.renderer.createElement('div');
      this.renderer.addClass(this.tooltipElement, 'custom-tooltip-main');

      const viewRef = this.viewContainerRef.createEmbeddedView(
        this.simplifiCustomTooltip,
      );
      const tooltipContent = viewRef.rootNodes[0];
      this.renderer.appendChild(this.tooltipElement, tooltipContent);

      const hostPos = this.elementRef.nativeElement.getBoundingClientRect();
      const left = hostPos.left;
      const top = hostPos.top - (this.tooltipElement?.offsetHeight ?? 0) + 18;

      this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
      this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
      this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);

      if (document.body && this.tooltipElement) {
        document.body.appendChild(this.tooltipElement);
        this.tooltipElement.addEventListener('mouseleave', event => {
          if (!this.isCursorOverTriggerOrTooltip(event)) {
            if (this.hideTimeout) {
              clearTimeout(this.hideTimeout);
            }
            this.hideTimeout = setTimeout(() => {
              this.hideTooltip();
            }, 100);
          }
        });
      }
    }
  }

  private hideTooltip() {
    const mainContainer = document.querySelector('.main-container');
    if (mainContainer) {
      this.renderer.setStyle(mainContainer, 'overflow', 'auto');
    }
    if (this.tooltipElement) {
      this.viewContainerRef.clear();
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  private isCursorOverTriggerOrTooltip(event: MouseEvent): boolean {
    if (!this.tooltipElement) {
      return false;
    }

    const triggerRect = this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipRect = this.tooltipElement.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    return (
      (mouseX >= triggerRect.left &&
        mouseX <= triggerRect.right &&
        mouseY >= triggerRect.top &&
        mouseY <= triggerRect.bottom) ||
      (mouseX >= tooltipRect.left &&
        mouseX <= tooltipRect.right &&
        mouseY >= tooltipRect.top &&
        mouseY <= tooltipRect.bottom)
    );
  }
  // sonarignore:end
}
