enum GeneralFieldsEnum {
  name = 'name',
  startDate = 'startDate',
  endDate = 'endDate',
  advertiserUrl = 'advertiserUrl',
  campaignGoal = 'campaignGoalId',
  contentCategories = 'contentCategories',
}

export const MandatoryFields = [
  GeneralFieldsEnum.name,
  GeneralFieldsEnum.startDate,
  GeneralFieldsEnum.endDate,
  GeneralFieldsEnum.advertiserUrl,
  GeneralFieldsEnum.campaignGoal,
  GeneralFieldsEnum.contentCategories,
];
