import {FilterDropdown} from '../interfaces';

export enum FilterOperator {
  EQ = 'eq',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte',
  NEQ = 'neq',
  BETWEEN = 'between',
}

export enum DateFilterOptions {
  eq = 'On',
  gt = 'After',
  gte = 'On or After',
  lt = 'Before',
  lte = 'On or Before',
  between = 'Between',
}

export enum BudgetFilterOptions {
  eq = 'Equal To',
  neq = 'Not Equal To',
  gt = 'Greater Than',
  lt = 'Less Than',
  between = 'Between',
}

export enum TargetFilterOptions {
  Keywords = 'hasKeywords',
  Addressable = 'hasAddressable',
  Ztv = 'hasZtv',
  Contextual = 'hasContextual',
}

export enum TargetFilterNameOptions {
  Keywords = 'Keywords',
  Addressable = 'Addressable',
  Ztv = 'ZipSelect',
  Contextual = 'Contextual',
  ZipSelect = 'Zip Adviser',
}

export enum GoalFilterOptions {
  Awareness = 'Awareness',
  Performance = 'Performance',
}

export const targetFilterOptionsValues: FilterDropdown[] = [
  {
    name: TargetFilterNameOptions.Keywords,
    value: TargetFilterOptions.Keywords,
  },
  {
    name: TargetFilterNameOptions.Addressable,
    value: TargetFilterOptions.Addressable,
  },
  {
    name: TargetFilterNameOptions.ZipSelect,
    value: TargetFilterOptions.Ztv,
  },
  {
    name: TargetFilterNameOptions.Contextual,
    value: TargetFilterOptions.Contextual,
  },
];

export const goalFilterOptionsValues: FilterDropdown[] = [
  {
    name: GoalFilterOptions.Awareness,
    value: GoalFilterOptions.Awareness,
  },
  {
    name: GoalFilterOptions.Performance,
    value: GoalFilterOptions.Performance,
  },
];

export const budgetFilterOptionsValues: FilterDropdown[] = [
  {
    name: BudgetFilterOptions.between,
    value: FilterOperator.BETWEEN,
  },
  {
    name: BudgetFilterOptions.eq,
    value: FilterOperator.EQ,
  },
  {
    name: BudgetFilterOptions.neq,
    value: FilterOperator.NEQ,
  },
  {
    name: BudgetFilterOptions.gt,
    value: FilterOperator.GT,
  },
  {
    name: BudgetFilterOptions.lt,
    value: FilterOperator.LT,
  },
];

export const dateFilterOptionsValues: FilterDropdown[] = [
  {
    name: DateFilterOptions.between,
    value: FilterOperator.BETWEEN,
  },
  {
    name: DateFilterOptions.eq,
    value: FilterOperator.EQ,
  },
  {
    name: DateFilterOptions.gt,
    value: FilterOperator.GT,
  },
  {
    name: DateFilterOptions.lt,
    value: FilterOperator.LT,
  },
  {
    name: DateFilterOptions.gte,
    value: FilterOperator.GTE,
  },
  {
    name: DateFilterOptions.lte,
    value: FilterOperator.LTE,
  },
];
