import {Injectable} from '@angular/core';
import {FilterDropdown} from '../../../shared/interfaces';

@Injectable()
export class GoalDropdownAdapterService {
  adaptToModel(goal: any): FilterDropdown {
    return {
      name: goal?.name,
      value: goal?.name,
    };
  }

  adaptFromModel(data: FilterDropdown): any {
    return data;
  }
}
