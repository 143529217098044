import {ApiService, IAdapter, PostAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';

export class GetPlanListVersionsCommand<T> extends PostAPICommand<T> {
  constructor(apiService: ApiService, adapter: IAdapter<T>, tenantId: string) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/versions`,
    );
  }
}
