import {OverAllTotals, Percentages, TacticsMetrics} from '.';

export class PlanResultTactics {
  metrics!: {[key: string]: TacticsMetrics};
  percentages!: Percentages;
  totals!: OverAllTotals;
  constructor(data: PlanResultTactics) {
    this.metrics = data.metrics ?? {};
    this.percentages = data.percentages ?? new Percentages();
    this.totals = data.totals ?? new OverAllTotals();
  }
}
