import {Injectable} from '@angular/core';
import {ApiService} from '@simplifi/core/api';
import {UserSessionStoreService} from '@simplifi/core/store';
import {GetPlanResultCommand} from 'src/app/main/planner/create-plan/commands';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';
import {PlanResultsAdapter} from '../create-plan/adapters/plan-results-adapter.service';

@Injectable()
export class PlanResultFacadeService {
  tenantId: string;
  constructor(
    private readonly apiService: ApiService,
    private readonly planResultsAdapter: PlanResultsAdapter,
    private readonly store: UserSessionStoreService,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  /**
   * The function `getPlanResultData` retrieves plan result data based on the provided plan ID, version
   * ID, and budget strategy.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of a specific plan.
   * @param {string} versionId - The `versionId` parameter is a string that represents the unique
   * identifier of a specific version of a plan. It is used to identify and retrieve data related to a
   * particular version of the plan.
   * @param {PlanBudgetStrategy} strategy - The `strategy` parameter in the `getPlanResultData`
   * function represents the PlanBudgetStrategy that is being used to retrieve plan results. This
   * parameter is passed to the `GetPlanResultCommand` constructor and then used in the execution of
   * the command to fetch the plan results based on the specified strategy
   * @returns The `execute()` method of the `GetPlanResultCommand` class is being called and its return
   * value is being returned.
   */
  getPlanResultData(
    planId: string,
    versionId: string,
    strategy: PlanBudgetStrategy,
  ) {
    const command = new GetPlanResultCommand(
      this.apiService,
      this.planResultsAdapter,
      this.tenantId,
      planId,
      versionId,
      strategy,
    );
    return command.execute();
  }
}
