import {ApiService, GetAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class GetBudgetConfigStatusCommand<T> extends GetAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    planId: string,
    planVersionId: string,
    tenantId: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${planVersionId}/get-tier-update-status`,
    );
  }
}
