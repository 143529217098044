import {Injectable} from '@angular/core';
import {UserSessionStoreService} from '@simplifi/core/store';
import PubNub from 'pubnub';

@Injectable({
  providedIn: 'root',
})
export class PubnubService {
  pubnub!: PubNub;
  constructor(private readonly store: UserSessionStoreService) {}

  initPubnub() {
    this.pubnub = new PubNub({
      subscribeKey: this.store.getPubnubKeys()?.subscribe,
      uuid: `${this.store.getUser().id}`,
      presenceTimeout: 40,
      heartbeatInterval: 30,
    });
    this.pubnub.setToken(this.store.getPubnubKeys().token);
    this.pubnub.subscribe({
      channelGroups: [
        `user_${this.store.getUser()?.defaultTenantId}_${this.store.getUser().id}`,
      ],
    });
  }

  unsubscribeChannel(channel: string) {
    this.pubnub.unsubscribe({
      channels: [channel],
    });
  }

  unsubscribeAllChannels() {
    this.pubnub.unsubscribeAll();
  }
}
