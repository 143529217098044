export class ResultTilesComponent {
  tileIcon!: string;
  tileTextUpper!: string;
  tileTextPrefix!: string;
  tileText!: string;
  tileTextLower!: string;
  constructor(data: ResultTilesComponent) {
    this.tileIcon = data.tileIcon;
    this.tileTextUpper = data.tileTextUpper;
    this.tileTextPrefix = data.tileTextPrefix;
    this.tileText = data.tileText;
    this.tileTextLower = data.tileTextLower;
  }
}
