import {Injectable} from '@angular/core';
import {
  DeletePlanKeywords,
  GetContentCategoryKeyword,
  GetKeywordChunks,
  GetPlanKeywords,
} from '../commands';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {Observable} from 'rxjs';
import {Words} from '../interfaces';
import {HttpParams} from '@angular/common/http';
import {UserSessionStoreService} from '@simplifi/core/store';
import {IAnyObject} from '@simplifi/core/i-any-object';
import {AuthService} from '@simplifi/core/auth';

/**
 * Service for managing keywords associated with a plan version, including retrieval and deletion.
 */
@Injectable()
export class KeywordsFacadeService {
  /**
   * The tenant ID associated with the user.
   */
  tenantId: string;

  /**
   * Constructs an instance of `KeywordsFacadeService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {AnyAdapter} anyAdapter - The adapter for handling API responses.
   * @param {UserSessionStoreService} store - The user session store service.
   * @param {AuthService} authService - The authentication service for handling authorization.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly store: UserSessionStoreService,
    private readonly authService: AuthService,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  /**
   * Retrieves keyword chunks based on user input and plan/version IDs.
   *
   * @param {string} tenantId - The tenant ID for the request.
   * @param {string} body - The user input for keywords.
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the list of keyword chunks.
   */
  getKeywordChunk(
    tenantId: string,
    body: string,
    planId: string,
    versionId: string,
  ): Observable<Words[]> {
    const data = {
      userInput: body.trim(),
      stateSessionId: this.store.getStateSession(),
    };

    return new Observable(subscriber => {
      const command: GetKeywordChunks<IAnyObject> = new GetKeywordChunks(
        this.apiService,
        this.anyAdapter,
        this.store,
        this.authService,
        tenantId,
        planId,
        versionId,
        subscriber,
      );

      command.parameters = {
        data,
      };

      command.execute();
      return () => {};
    });
  }

  /**
   * Retrieves keywords associated with a specific plan and version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the list of plan keywords.
   */
  getPlanKeywords(planId: string, versionId: string): Observable<Words[]> {
    return new Observable(subscriber => {
      const command: GetPlanKeywords<IAnyObject> = new GetPlanKeywords(
        this.apiService,
        this.anyAdapter,
        this.store,
        this.authService,
        this.tenantId,
        planId,
        versionId,
        subscriber,
      );
      if (this.store.getStateSession()) {
        command.parameters = {
          query: new HttpParams().set(
            'stateSessionId',
            this.store.getStateSession(),
          ),
        };
      }

      command.execute();
      return () => {};
    });
  }

  /**
   * Deletes specific keywords or all keywords for a given plan and version.
   *
   * @param {string} versionId - The ID of the plan version.
   * @param {string} planId - The ID of the plan.
   * @param {Words} keyword - The keyword to delete.
   *
   * @returns {Observable<void>} - An observable that completes when the keyword(s) are deleted.
   */
  deleteKeywords(versionId: string, planId: string, keyword: Words) {
    const command: DeletePlanKeywords<Words> = new DeletePlanKeywords(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      planId,
      versionId,
    );

    command.parameters = {};

    if (keyword) {
      command.parameters['data'] = {
        keywordId: keyword.id,
        stateSessionId: this.store.getStateSession(),
      };
    } else {
      command.parameters['data'] = {
        stateSessionId: this.store.getStateSession(),
      };
      command.parameters['query'] = new HttpParams().set('deleteAll', true);
    }

    return command.execute();
  }

  /**
   * Retrieves content category keywords for a specific plan and version.
   *
   * @param {string} planId - The ID of the plan.
   * @param {string} versionId - The ID of the plan version.
   *
   * @returns {Observable<Words[]>} - An observable that emits the list of content category keywords.
   */
  getContentCategoryKeywords(
    planId: string,
    versionId: string,
  ): Observable<Words[]> {
    return new Observable(subscriber => {
      const command: GetContentCategoryKeyword<IAnyObject> =
        new GetContentCategoryKeyword(
          this.apiService,
          this.anyAdapter,
          this.store,
          this.authService,
          this.tenantId,
          planId,
          versionId,
          subscriber,
        );
      if (this.store.getStateSession()) {
        command.parameters = {
          query: new HttpParams().set(
            'stateSessionId',
            this.store.getStateSession(),
          ),
        };
      }

      command.execute();
      return () => {};
    });
  }
}
