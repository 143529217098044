import {Injectable} from '@angular/core';
import {IAnyObject} from '@simplifi/core/i-any-object';
import saveAs from 'file-saver';

@Injectable()
export class DownloadHelperService {
  constructor() {}
  processPlanDownloads(response: IAnyObject) {
    const contentDisposition = response['headers'].get('content-disposition');
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = contentDisposition?.match(filenameRegex);

    // Use optional chaining to check for matches and extract the filename
    const customFileName =
      matches?.[1]?.replace(/['"]/g, '') || 'plan-download.csv';

    const blob: Blob = response['body'];
    saveAs(blob, customFileName);
  }
}
