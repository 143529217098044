import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Budget, MediaType} from '../models';
import {TargetMediaType} from '../models/target-media-type.model';
import {MediaTypeName, PlanTarget, TargetIconMap} from '@simplifi/shared/enums';

@Injectable()
export class BudgetAdapter implements IAdapter<Budget> {
  /**
   * The function `adaptToModel` takes a response object and converts it into a Words object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `Words` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */

  public adaptToModel(resp: any[]): Budget {
    const targetBudgetMap: Record<PlanTarget, number> = {
      [PlanTarget.Addressable]: 0,
      [PlanTarget.Contextual]: 0,
      [PlanTarget.keyword]: 0,
      [PlanTarget.Ztv]: 0,
    };
    const targetMap: Record<PlanTarget, TargetMediaType> = {
      [PlanTarget.Addressable]: new TargetMediaType(),
      [PlanTarget.Contextual]: new TargetMediaType(),
      [PlanTarget.keyword]: new TargetMediaType(),
      [PlanTarget.Ztv]: new TargetMediaType(),
    };
    const planBudget = new Budget();
    resp.forEach(targetMediaType =>
      this.processTargetMediaType(
        targetMediaType,
        planBudget,
        targetMap,
        targetBudgetMap,
      ),
    );
    planBudget.targetMediaTypeMappings =
      this.filterAndMapTargetTypes(targetMap);
    planBudget.targetMediaTypeMappings.forEach(target => {
      target.targetBudget = Math.round(targetBudgetMap[target.targetType]);
    });
    return planBudget;
  }

  private processTargetMediaType(
    targetMediaType: any,
    planBudget: Budget,
    targetMap: Record<PlanTarget, TargetMediaType>,
    targetBudgetMap: Record<PlanTarget, number>,
  ): void {
    const target = targetMap[targetMediaType.targetType as PlanTarget];
    const mediaType = this.createMediaType(targetMediaType);

    target.targetType = targetMediaType.targetType;
    target.recommendedBudgetPercentange =
      targetMediaType?.recommendedPercentage;
    this._assignMediaType(target, mediaType);

    targetBudgetMap[targetMediaType.targetType as PlanTarget] +=
      mediaType.allocatedBudget;
    planBudget.total = Number(targetMediaType.totalBudget);
    planBudget.allocated += mediaType.allocatedBudget;
    target.targetIcon = TargetIconMap[targetMediaType.targetType as PlanTarget];
  }

  private createMediaType(targetMediaType: any): MediaType {
    const mediaType = new MediaType();
    mediaType.id = targetMediaType?.id;
    mediaType.name = targetMediaType?.mediaType.name;
    mediaType.isChecked = !!Number(targetMediaType?.recommendedBudget ?? 0);
    mediaType.allocatedBudget = Number(targetMediaType?.recommendedBudget ?? 0);
    mediaType.defaultBudget = Number(
      targetMediaType?.recommendedBudgetDefault ?? 0,
    );
    mediaType.allocatedBudgetPercent = this._calculateBudgetPercentage(
      mediaType.allocatedBudget,
      targetMediaType?.totalBudget,
    );
    mediaType.defaultBudgetPercent = this._calculateBudgetPercentage(
      mediaType.defaultBudget,
      targetMediaType?.totalBudget,
    );
    mediaType.recommendedMinBudget = targetMediaType.recommendedMinBudget;
    mediaType.recommendedDefaultMinBudget =
      targetMediaType.recommendedMinBudgetDefault;
    mediaType.recommendedDefaultMaxBudget =
      targetMediaType.recommendedMaxBudgetDefault;
    mediaType.recommendedMaxBudget = targetMediaType.recommendedMaxBudget;
    mediaType.maxBid = targetMediaType?.actualCpm;
    mediaType.defaultMaxBid = targetMediaType?.defaultCpm;
    return mediaType;
  }

  private filterAndMapTargetTypes(
    targetMap: Record<PlanTarget, TargetMediaType>,
  ): TargetMediaType[] {
    return Object.values(targetMap).filter(target => target.targetType);
  }

  private _assignMediaType(target: TargetMediaType, mediaType: MediaType) {
    switch (mediaType.name) {
      case MediaTypeName.OTT:
        target.ottCtv = mediaType;
        break;
      case MediaTypeName.Display:
        target.display = mediaType;
        break;
      case MediaTypeName.Video:
        target.video = mediaType;
        break;
    }
  }

  private _calculateBudgetPercentage(
    budget: number,
    totalBudget: number,
  ): number {
    return Math.round((budget / totalBudget) * 100);
  }

  adaptFromModel(data: Budget): any {
    return data;
  }
}
