import {HighChartsDonutDataset} from '.';

export class BudgetReachDonutType {
  household!: HighChartsDonutDataset[];
  population!: HighChartsDonutDataset[];
  constructor() {
    this.household = [new HighChartsDonutDataset()];
    this.population = [new HighChartsDonutDataset()];
  }
}
