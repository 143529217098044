import {ApiService, IAdapter, PatchAPICommand} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class UpdateFrequencyConfigCommand<T> extends PatchAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId: string,
    configKey: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plan-configs/${configKey}`,
    );
  }
}
