import {GeneralAiResponse} from '@simplifi/shared/interfaces';

export class ChatMetadata {
  planId!: string;
  versionId!: string;
  sessionId!: string;
  currentQuestion?: string;
  previousResponse!: GeneralAiResponse;
  constructor() {
    this.previousResponse = {} as GeneralAiResponse;
    this.currentQuestion = '';
  }
}
