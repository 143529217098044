import {Injectable} from '@angular/core';
import {ApiService} from '@simplifi/core/api';
import {Budget, BudgetMediaType} from '../models';
import {
  AddBulkBudgetMediaTypesCommand,
  GetPlanVersionBudgetReachSummary,
  GetTargetMediaTypeMappings,
} from '../commands';
import {AnyAdapter, UserSessionStoreService} from '@simplifi/core/index';
import {BudgetAdapter, BudgetMediaTypeAdapter} from '../adapters';
import {PlanBudgetStrategy} from '@simplifi/shared/enums';

/**
 * Service for managing budget-related operations, including adding media types and fetching media type mappings.
 */
@Injectable()
export class BudgetFacadeService {
  /**
   * The tenant ID associated with the user.
   */
  tenantId: string;

  /**
   * Constructs an instance of `BudgetFacadeService`.
   *
   * @param {ApiService} apiService - The API service to make HTTP requests.
   * @param {BudgetAdapter} budgetAdapter - The adapter for handling budget data.
   * @param {BudgetMediaTypeAdapter} budgetMediaTypeAdapter - The adapter for handling budget media types.
   * @param {UserSessionStoreService} store - The user session store service.
   */
  constructor(
    private readonly apiService: ApiService,
    private readonly budgetAdapter: BudgetAdapter,
    private readonly budgetMediaTypeAdapter: BudgetMediaTypeAdapter,
    private readonly store: UserSessionStoreService,
    private readonly anyAdapter: AnyAdapter,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  addbudgetMediaTypes(
    budget: Budget,
    planId: string,
    versionId: string,
    strategy: PlanBudgetStrategy,
  ) {
    const command: AddBulkBudgetMediaTypesCommand<BudgetMediaType> =
      new AddBulkBudgetMediaTypesCommand(
        this.apiService,
        this.budgetMediaTypeAdapter,
        planId,
        versionId,
        strategy,
        this.tenantId,
      );

    command.parameters = {
      data: budget,
    };

    return command.execute();
  }

  fetchTargetMediaTypeMappings(
    planId: string,
    planVersionId: string,
    strategy: PlanBudgetStrategy,
  ) {
    const command = new GetTargetMediaTypeMappings(
      this.apiService,
      this.budgetAdapter,
      this.tenantId,
      planId,
      planVersionId,
      strategy,
    );
    return command.execute();
  }

  getBudgetReachSummary(planId: string, planVersionId: string) {
    const command = new GetPlanVersionBudgetReachSummary(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      planId,
      planVersionId,
    );
    return command.execute();
  }
}
