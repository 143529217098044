/* eslint-disable @typescript-eslint/no-explicit-any */
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {Numbers} from '@simplifi/shared/enums/numbers.enums';
import moment from 'moment';

@Component({
  selector: 'simplifi-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class DateRangeComponent {
  @Input() form!: NgForm;
  @Input() label!: string;
  @Input() id!: string;
  @Input() disabled = false;
  @Input() prefixIcon = '';
  @Input() suffixIcon = '';
  @Input() required = false;
  @Input() prefixSvg = '';
  @Input() hint = '';
  @Input() inputLabel = true;
  @Input() singleDatePicker = false;
  @Input() selected!: {startDate: Date; endDate: Date};
  @Output() selectedChange = new EventEmitter();

  onChange(event: Event) {
    this.selectedChange.emit(event);
  }

  ranges: any = // NOSONAR
    {
      Today: [moment(), moment()],
      Tomorrow: [
        moment().add(Numbers.numberOne, 'days'),
        moment().add(Numbers.numberOne, 'days'),
      ],
      'Next 7 Days': [moment(), moment().add(Numbers.numberSix, 'days')],
      'Next 30 Days': [moment(), moment().add(Numbers.twentyNine, 'days')],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Next Month': [
        moment().add(Numbers.numberOne, 'month').startOf('month'),
        moment().add(Numbers.numberOne, 'month').endOf('month'),
      ],
    };

  singleRanges: any = // NOSONAR
    {
      Today: [moment(), moment()],
      Tomorrow: [
        moment().add(Numbers.numberOne, 'days'),
        moment().add(Numbers.numberOne, 'days'),
      ],
    };
}
