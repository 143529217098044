export class PlanResultSummary {
  locationCount?: string;
  totalHouseholds?: string;
  totalPopulations?: string;
  blendedCpm?: string;
  constructor() {
    this.locationCount = '0';
    this.totalHouseholds = '0';
    this.totalPopulations = '0';
    this.blendedCpm = '0';
  }
}
