import {Injectable} from '@angular/core';
import {
  AnyAdapter,
  ApiService,
  UserSessionStoreService,
} from '@simplifi/core/index';
import {
  GetFrequencyConfigCommand,
  UpdateFrequencyConfigCommand,
} from '../../main/profile/frequency-cap/commands';
import {StringToNumberAdapter} from '@simplifi/core/api/adapters/string-to-number-adapter.service';
import {PlanConfigKeys} from '../../main/profile/constants';
import {TacticsFrequencyConfig} from '@simplifi/shared/models/tactics-frequency-config.model';
import {PlanConfiguration} from '@simplifi/shared/models/plan-configurations.model';
import {FrequencyConfig} from '@simplifi/shared/models/frequency-config.model';

@Injectable()
export class PlanConfigurationFacadeService {
  tenantId: string;
  constructor(
    private readonly anyAdapter: AnyAdapter,
    private readonly apiService: ApiService,
    private readonly store: UserSessionStoreService,
    private readonly stringToNumber: StringToNumberAdapter,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  getConfig(configKey: PlanConfigKeys) {
    const command = new GetFrequencyConfigCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      configKey,
    );
    return command.execute();
  }

  updatePlanFrequencyConfiguration(configKey: PlanConfigKeys, data: object) {
    const configData = new PlanConfiguration();
    if (configKey === PlanConfigKeys.BUDGET_FREQUENCY_CONFIG) {
      configData.frequencyConfig = data as FrequencyConfig;
    }
    if (configKey === PlanConfigKeys.TACTICS_FREQUENCY_CONFIG) {
      configData.tacticsConfig = data as TacticsFrequencyConfig;
    }
    delete configData.configKey;
    const command: UpdateFrequencyConfigCommand<Partial<PlanConfiguration>> =
      new UpdateFrequencyConfigCommand(
        this.apiService,
        this.stringToNumber,
        this.tenantId,
        configKey,
      );
    command.parameters = {
      data: configData,
    };
    return command.execute();
  }
}
