import {Stepper} from '../enums';

export class PlanNavigation {
  [Stepper.GENERAL]: GeneralWidget = new GeneralWidget();
  [Stepper.TARGET]: TargetWidget = new TargetWidget();
  [Stepper.BUDGET]: BudgetWidget = new BudgetWidget();
}

export class GeneralWidget {
  formSection: WidgetNavigation = new WidgetNavigation();
  location: WidgetNavigation = new WidgetNavigation();
}

export class TargetWidget {
  addressable: WidgetNavigation = new WidgetNavigation();
  keywords: WidgetNavigation = new WidgetNavigation();
  ztv: WidgetNavigation = new WidgetNavigation();
  contextual: WidgetNavigation = new WidgetNavigation();
}

export class BudgetWidget {
  budget: WidgetNavigation = new WidgetNavigation();
  suggestedBudget: WidgetNavigation = new WidgetNavigation();
  constructor() {
    this.suggestedBudget.formValid = true;
  }
}

export class WidgetNavigation {
  formValid = false;
  unsave = false;
}
