export class Pagination {
  rowCount: number;
  currentPage: number;
  rowLimit: number;

  constructor(data: Pagination) {
    this.rowCount = data.rowCount;
    this.currentPage = data.currentPage;
    this.rowLimit = data.rowLimit;
  }
}
