import {ApiService, IAdapter, PostChunkAPICommand} from '@simplifi/core/api';
import {AuthService} from '@simplifi/core/auth';
import {UserSessionStoreService} from '@simplifi/core/store';
import {environment} from '@simplifi/env/environment';
import {Subscriber} from 'rxjs';
export class GetKeywordChunks<T> extends PostChunkAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    store: UserSessionStoreService,
    authservice: AuthService,
    tenantId: string,
    planId: string,
    versionId: string,
    subscriber: Subscriber<T>,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${versionId}/keywords/search/chunk`,
      subscriber,
      store,
      authservice,
    );
  }
}
