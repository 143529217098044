import {TileKeys} from '../constants';
import {ResultTilesComponent} from './result-tiles-component.model';

export class ResultTiles {
  [TileKeys.flightDate]!: ResultTilesComponent;
  [TileKeys.blendedCpm]!: ResultTilesComponent;
  [TileKeys.householdReach]!: ResultTilesComponent;
  [TileKeys.populationReach]!: ResultTilesComponent;
  [TileKeys.locationCount]!: ResultTilesComponent;
  constructor() {
    this.flightDate = new ResultTilesComponent({
      tileIcon: ' el-icon-date',
      tileTextUpper: '',
      tileTextPrefix: '',
      tileText: '',
      tileTextLower: 'Flight Dates',
    });
    this.blendedCpm = new ResultTilesComponent({
      tileIcon: ' el-icon-currency',
      tileTextUpper: '',
      tileTextPrefix: '$',
      tileText: '',
      tileTextLower: 'Blended CPM',
    });
    this.householdReach = new ResultTilesComponent({
      tileIcon: ' el-icon-home',
      tileTextUpper: 'Estimated',
      tileTextPrefix: '',
      tileText: '',
      tileTextLower: 'Households Reached',
    });
    this.populationReach = new ResultTilesComponent({
      tileIcon: ' el-icon-audience',
      tileTextUpper: 'Estimated',
      tileTextPrefix: '',
      tileText: '',
      tileTextLower: 'People Reached',
    });
    this.locationCount = new ResultTilesComponent({
      tileIcon: ' el-icon-location-outline',
      tileTextUpper: '',
      tileTextPrefix: '',
      tileText: '',
      tileTextLower: 'Locations',
    });
  }
}
