import {ApiService, GetAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';

export class GetPlanVersionCitiesCommand<T> extends GetAPICommand<T> {
  constructor(
    apiService: ApiService,
    adapter: IAdapter<T>,
    tenantId?: string,
    planId?: string,
    versionId?: string,
  ) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.planFacadeUrl}/tenants/${tenantId}/plans/${planId}/versions/${versionId}/cities`,
    );
  }
}
