import {FilterDropdown} from '../interfaces';

export enum PlanStatus {
  Complete = 'complete',
  Draft = 'draft',
  Processing = 'processing',
  Downloaded = 'downloaded',
  Expired = 'expired',
  Error = 'error',
}

export enum PlanStatusName {
  Complete = 'Complete',
  Draft = 'Draft',
  Processing = 'Processing',
  Downloaded = 'Downloaded',
  Expired = 'Expired',
  Error = 'Error',
}

export const planStatusFilterOptions: FilterDropdown[] = [
  {
    name: PlanStatusName.Complete,
    value: PlanStatus.Complete,
    srcPath: './assets/images/planner/list/Complete.svg',
  },
  {
    name: PlanStatusName.Draft,
    value: PlanStatus.Draft,
    iconClass: 'el-icon-status-draft status-draft',
  },
  {
    name: PlanStatusName.Downloaded,
    value: PlanStatus.Downloaded,
    srcPath: './assets/images/planner/list/Downloaded.svg',
  },
  {
    name: PlanStatusName.Expired,
    value: PlanStatus.Expired,
    iconClass: 'el-icon-status-ended els-icon--regular',
  },
  {
    name: PlanStatusName.Error,
    value: PlanStatus.Error,
    iconClass: 'el-icon-status-error status-error',
  },
];

export enum PlanStatusIconName {
  Complete = 'Complete.svg',
  Draft = 'Draft.svg',
  Processing = 'Processing.svg',
  Downloaded = 'Downloaded.svg',
  Expired = 'Expired.svg',
  Error = 'Error.svg',
}

export const statusIconMap = new Map<string, string>([
  [PlanStatus.Complete, PlanStatusIconName.Complete],
  [PlanStatus.Draft, PlanStatusIconName.Draft],
  [PlanStatus.Processing, PlanStatusIconName.Processing],
  [PlanStatus.Downloaded, PlanStatusIconName.Downloaded],
  [PlanStatus.Expired, PlanStatusIconName.Expired],
  [PlanStatus.Error, PlanStatusIconName.Error],
]);
