import {Injectable} from '@angular/core';
import {PlanBudgetStrategy, PlanStatus} from '@simplifi/shared/enums';
import {PlanView} from '@simplifi/shared/models';
import {Words} from '../create-plan/interfaces';
import {Plan, PlanDetailDto, Suggestions} from '../create-plan/models';
import {BudgetType} from '../create-plan/constants';
@Injectable()
export class PlanAdapterService {
  adaptToModel(planVersions: any): PlanView {
    let contentCategoryIds: string[] = [];
    let ztvZipCodes: Words[] = [];
    let contextualKeywords: Words[] = [];
    if (planVersions.contentCategories) {
      contentCategoryIds = planVersions.contentCategories.map(
        (category: {content_category_id: string; name: string}) =>
          category.content_category_id,
      );
    }
    const respData = new PlanView();
    respData.id = planVersions.id;
    respData.versionId = planVersions.versionId;
    respData.currentVersion = planVersions.currentVersion;
    respData.isCurrentVersion = planVersions.isCurrentVersion;
    respData.disabled =
      !planVersions.isCurrentVersion ||
      planVersions.status === PlanStatus.Downloaded;
    respData.status = planVersions.status;
    respData.name = planVersions.name;
    respData.advertiserUrl = planVersions.advertiserUrl;
    respData.campaignGoalId = planVersions.campaignGoalId;
    respData.campaignGoalName = planVersions.campaignGoalName;
    respData.budgetType = planVersions.budgetType;
    respData.budget[PlanBudgetStrategy.Max].amount = planVersions.budgetAmount;
    respData.budget[PlanBudgetStrategy.Max].budgetAllocationType =
      planVersions.budgetAllocationType;
    respData.locationType = planVersions.locationType;
    respData.contentCategories = contentCategoryIds;
    respData.contentCategoriesName = planVersions.contentCategories?.map(
      (ele: {content_category_id: string; name: string}) => {
        return {id: ele.content_category_id, name: ele.name};
      },
    );
    respData.deviceTypes = planVersions.deviceTypes?.map(
      (ele: {device_type_id: string; name: string}) => {
        return {id: ele.device_type_id, name: ele.name};
      },
    );
    respData.locations = planVersions.locations ?? [];
    respData.keyword = new Suggestions();
    respData.addressable = new Suggestions();
    respData.ztv = {
      enabled: false,
      words: ztvZipCodes,
    };
    respData.contextual = {
      enabled: false,
      words: contextualKeywords,
    };
    respData.hasAddressable = planVersions.hasAddressable;
    respData.hasKeywords = planVersions.hasKeywords;
    respData.hasContextual = planVersions.hasContextual;
    respData.hasZtv = planVersions.hasZtv;
    respData.budget[PlanBudgetStrategy.Max].total = planVersions.totalBudget;
    respData.budget[PlanBudgetStrategy.Suggested].total =
      planVersions.suggestedBudget;
    respData.versionTag = planVersions.version;
    respData.createdOn = planVersions.createdOn;
    respData.goalAmount = Number(planVersions?.campaignGoalAmount);
    respData.planId = planVersions.id;
    respData.version = planVersions.version;
    respData.child = planVersions.version ?? [];
    respData.goalName = planVersions.campaignGoalName;
    respData.currentPage = planVersions.currentPage ?? 'general';
    respData.editForm = planVersions.editForm;
    if (planVersions?.startDate) {
      respData.startDate = planVersions?.startDate;
    }
    if (planVersions?.endDate) {
      respData.endDate = planVersions?.endDate;
    }
    respData.budget.aiSuggested = !!planVersions?.isAiSuggestedBudget;
    return respData;
  }

  adaptFromModel(data: Plan): PlanDetailDto {
    const reqData: PlanDetailDto = {};
    reqData.plan = {
      name: data.name ?? '',
    };
    reqData.planVersion = {
      startDate: data.startDate ?? '',
      endDate: data.endDate ?? '',
      advertiserUrl: data.advertiserUrl ?? '',
      campaignGoalId: data.campaignGoalId ?? '',
      budgetAmount: Number(data.budget[PlanBudgetStrategy.Max].amount ?? 0),
      budgetAllocationType:
        data.budget[PlanBudgetStrategy.Max].budgetAllocationType,
      locationType: data.locationType,
      totalBudget: Number(data.budget[PlanBudgetStrategy.Max].total ?? 0),
      budgetType: data.budgetType ?? BudgetType.LIFETIME,
      suggestedBudget: Number(
        data.budget[PlanBudgetStrategy.Suggested].total ?? 0,
      ),
      isAiSuggestedBudget: data.budget.aiSuggested,
    };
    reqData.planVersionContentCategories = data.contentCategories;
    reqData.planVersionDeviceTypes = data.deviceTypes.map(
      (deviceType: {id: string; name: string}) => deviceType.id,
    );
    return reqData;
  }
}
