
export const environment = {
  production: false,
  homePath: '/main/planner',
  loginPath: '/login',
  baseApiUrl: 'https://simplifidev-api.filelogix.net/',
  clientId: 'superadmin',
  clientSecret: 'saqw21!@',
  cryptoSecretKey: 'simplifi-plantool-hopls',
  simplifiLogo: '/assets/ico/simplifi-iplanner-logo.svg',
  testPassword: 'test',
  messageTimeout: 5000,
  authServiceUrl: `auth-service`,
  tenantUserFacade: `tenant-user-facade`,
  chatFacadeApiUrl: `chat-facade`,
  authClientId: 1,
  planFacadeUrl: `plan-facade`,
  recaptchaKey: '6Le6frUpAAAAAHpmRELm-SpLUGZId-4ZrtuUTIge',
  agGridLicence: 'Using_this_{AG_Grid}_Enterprise_key_{AG-058659}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Simplifi_Holdings,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{LeGrid}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{LeGrid}_need_to_be_licensed___{LeGrid}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{18_May_2025}____[v3]_[01]_MTc0NzUyMjgwMDAwMA==f641394cf00e4b8811161e6496f3db93',
  fsOrgId: 'o-1Z07T1-na1',
  fsDevMode: false,
  fsDebug: false,
  fsNamespace: 'SPT_DEV',
  appUrl: 'https://simplifidev.filelogix.net/',
};