import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

type InputType =
  | 'text'
  | 'email'
  | 'password'
  | 'number'
  | 'date'
  | 'checkbox'
  | 'phone';
type stringInput = string | string[];
@Component({
  selector: 'simplifi-input-box',
  templateUrl: './input-box.component.html',
  styleUrls: ['./input-box.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
})
export class InputBoxComponent {
  @Input() form!: NgForm;
  @Input() type: InputType = 'text';
  @Input() label?: string;
  @Input() id!: string;
  @Input() name!: string;
  @Input() minlength!: number;
  @Input() maxlength!: number;
  @Input() inputLabel = true;
  @Input() hasSearch = false;
  @Input() color = 'primary';
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() inputModel!: stringInput | boolean | undefined | Date | number;
  @Input() pattern!: string;
  @Input() width = '';
  @Input() maxWidth = '';
  @Input() prefixIcon = '';
  @Input() prefixText = '';
  @Input() suffixIcon = '';
  @Input() prefixSvg = '';
  @Input() suffixSvg = '';
  @Input() suffixText = '';
  @Input() horizontalField = false;
  @Input() inputHelpText = '';
  @Input() suffixLabelIcon = '';
  @Input() validationMessage = '';
  @Input() showTooltip = '';
  @Input() isSearchInput = false;
  @Output() inputModelChange = new EventEmitter();
  @Input() mask = '';
  @Input() minValue!: number;

  @ViewChild('rootInput') rootInput!: ElementRef;
  onChange(event: Event) {
    this.inputModelChange.emit(event);
  }
  clearInput() {
    this.inputModel = '';
    this.inputModelChange.emit(this.inputModel);
  }
  isString(value: unknown): value is string {
    return typeof value === 'string';
  }
  focusOut() {
    if (this.minValue) {
      this.inputModelChange.emit(this.rootInput.nativeElement.value);
    }
  }
}
