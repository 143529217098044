import {HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthTokenSkipHeader, ErrToastSkipHeader} from '../constants';
import {UserSessionStoreService} from '../store';
import {NgxPermissionsService} from 'ngx-permissions';
import {
  catchError,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

import {
  GetCurrentUserCommand,
  GetTokenCommand,
  LoginCommand,
  LogoutCommand,
  RefreshTokenCommand,
} from './commands';
import {LoginModel, TokenResponseModel} from './models';
import {AnyAdapter, ApiService} from '../api';
import {LoggedInUserAdapterService, LoginAdapterService} from './adapters';
import {environment} from '@simplifi/env/environment';
import {User} from './models/user.model';
import {CoreAuthModule} from './auth.module';
import {IToaster, TOASTER_SERVICE_KEY} from '../toaster';
import {GetUserById} from '@simplifi/main/profile/commands';
import {UserDto} from '@simplifi/main/profile/models';
import {UserProfileAdapterService} from '@simplifi/main/profile/adapters';
import {UserProfileDataService} from '@simplifi/shared/services/user-profile-data.service';
import {FullStory} from '@fullstory/browser';

const googleAuthUrl = `/auth/google`;
const facebookAuthUrl = `/auth/facebook`;
const samlAuthUrl = `/auth/saml`;

@Injectable({
  providedIn: CoreAuthModule,
})
export class AuthService {
  private readonly authTokenSkipHeader = new HttpHeaders().set(
    AuthTokenSkipHeader,
    '',
  );
  private readonly errorToastSkipHeader = new HttpHeaders().set(
    ErrToastSkipHeader,
    '',
  );
  constructor(
    private readonly router: Router,
    private readonly store: UserSessionStoreService,
    private readonly apiService: ApiService,
    private readonly currentUserAdapter: LoggedInUserAdapterService,
    private readonly loginAdapter: LoginAdapterService,
    private readonly anyAdapter: AnyAdapter,
    private readonly permissionsService: NgxPermissionsService,
    private readonly userProfileDataService: UserProfileDataService,
    @Inject(TOASTER_SERVICE_KEY) private readonly toaster: IToaster,
    private readonly userProfileAdapter: UserProfileAdapterService,
  ) {}

  public isLoggedIn(): Observable<boolean> {
    return this.currentUser().pipe(
      switchMap(user => {
        if (user?.id && this.store.getAccessToken()) {
          return of(true);
        } else {
          return of(false);
        }
      }),
    );
  }

  public currentUser(): Observable<User> {
    const user = this._loadUserFromStore();
    const hasToken = !!this.store.getAccessToken();
    if (user) {
      return of(user);
    } else if (!hasToken) {
      return throwError(() => new Error('No token available'));
    } else {
      const command: GetCurrentUserCommand<User> = new GetCurrentUserCommand(
        this.apiService,
        this.currentUserAdapter,
      );
      return command.execute().pipe(
        tap(res => {
          this.store.setUser(res);
          if (environment.fsOrgId && environment.fsOrgId !== 'undefined') {
            this.subscribeToFS();
          }
          this._loadPermissions(res.permissions);
          /**Set User Details On Page Refresh Start */
          const params = new HttpParams();
          const command: GetUserById<UserDto> = new GetUserById(
            this.apiService,
            this.userProfileAdapter,
            this.store.getUser().defaultTenantId,
            this.store.getUser().id,
          );
          command.parameters = {
            query: params,
          };
          command
            .execute()
            .pipe(take(1))
            .subscribe(response => {
              const user = this.store.getUser();
              user.firstName = response.name;
              this.store.setUser(user);
              this.userProfileDataService.setData(user);
            });
          /**Set User Details On Page Refresh End */
        }),
      );
    }
  }

  public login(username: string, password: string): Observable<LoginModel> {
    this.store.setUser({
      username,
    } as User);
    const command: LoginCommand<LoginModel> = new LoginCommand(
      this.apiService,
      this.loginAdapter,
    );
    command.parameters = {
      data: {
        username: username.toLowerCase(),
        password,
        clientId: environment.clientId,
        clientSecret: environment.clientSecret,
      },
      observe: 'response',
      headers: this.authTokenSkipHeader,
    };
    return command.execute();
  }

  public authorize(
    secret: string,
    otp?: string,
  ): Observable<boolean | TokenResponseModel> {
    if (!secret) {
      this.router.navigate(['login']);
    }
    const command: GetTokenCommand<TokenResponseModel> = new GetTokenCommand(
      this.apiService,
      this.anyAdapter,
    );
    command.parameters = {
      data: {
        clientId: environment.clientId,
        code: secret,
      },
      headers: this.authTokenSkipHeader,
    };
    if (otp) {
      command.parameters.data.otp = otp;
    }
    return command.execute().pipe(
      map(response => {
        const redirectTo =
          this.store.getLastAccessedUrl() ?? environment.homePath;
        if (response.accessToken && response.refreshToken) {
          this.store.saveAccessToken(response.accessToken);
          this.store.saveRefreshToken(response.refreshToken);
          this.store.saveTokenExpiry(Number(response.expires));
          this.store.setPubnubKeys({
            subscribe: response.pubNubSubKey,
            token: response.pubnubToken,
          });
          this.toaster.success('Login successful.', '');
          this.store.setCheckPlanDraft(true);
          this.store.setDefaultChatMessagesCheck(true);
          this.router.navigate([redirectTo]);
          return true;
        }
        return false;
      }),
    );
  }

  public refreshToken(): Observable<TokenResponseModel | boolean> {
    const refreshToken = this.store.getRefreshToken();
    if (!refreshToken) {
      return of(false);
    }
    const command: RefreshTokenCommand<TokenResponseModel> =
      new RefreshTokenCommand(this.apiService, this.anyAdapter);
    command.parameters = {
      data: {
        refreshToken,
      },
      headers: this.errorToastSkipHeader,
    };
    return command
      .execute()
      .pipe(
        tap({
          next: response => {
            if (response.accessToken && response.refreshToken) {
              this.store.saveAccessToken(response.accessToken);
              this.store.saveRefreshToken(response.refreshToken);
              this.store.saveTokenExpiry(Number(response.expires));
              this.store.setPubnubKeys({
                subscribe: response.pubNubSubKey,
                token: response.pubnubToken,
              });
            } else {
              this.logout();
            }
          },
          error: () => {
            this.clearAllData();
          },
        }),
      )
      .pipe(catchError(this.handleError));
  }

  public logout(): Observable<boolean> {
    const refreshToken = this.store.getRefreshToken();
    if (!refreshToken) {
      this.clearAllData();
      return of(false);
    }
    const command: LogoutCommand<unknown> = new LogoutCommand(
      this.apiService,
      this.anyAdapter,
    );
    command.parameters = {
      data: {
        refreshToken,
      },
      headers: this.errorToastSkipHeader,
    };
    return command.execute().pipe(
      map(() => {
        this.clearAllData();
        return true;
      }),
    );
  }

  private subscribeToFS() {
    FullStory('setProperties', {
      type: 'user',
      properties: {
        displayName: this.store.getUser().firstName,
        email: this.store.getUser().email,
      },
    });
  }

  private _loadPermissions(permissions: string[]) {
    const perms = this.permissionsService.getPermissions();
    const entityPerms: string[] = [];
    for (const key in perms) {
      if (Object.hasOwn(perms, key) && key.includes('/')) {
        entityPerms.push(key);
      }
    }

    this.permissionsService.loadPermissions([...permissions, ...entityPerms]);
  }

  private _loadUserFromStore() {
    const user = this.store.getUser();

    if (user?.id) {
      this._checkIfPermissionsAlreadyExists(user.permissions)
        .pipe(
          tap(exists => {
            if (!exists) {
              this._loadPermissions(user.permissions);
            }
          }),
        )
        .pipe(take(1))
        .subscribe();
      return user;
    }
    return null;
  }

  private _checkIfPermissionsAlreadyExists(permissions: string[]) {
    return from(this.permissionsService.hasPermission(permissions));
  }

  private clearAllData() {
    this.store.clearAll();
    this.permissionsService.flushPermissions();
    this.redirectTo('login');
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(
      () =>
        new Error(`Something bad happened; please try again later. ${error}`),
    );
  }

  redirectTo(url: string) {
    this.router.navigateByUrl(url);
  }

  oAuthLogin(url: string): void {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = `${environment.baseApiUrl}${environment.tenantUserFacade}${url}`;
    form.style.display = 'none';

    const clientId = document.createElement('input');
    clientId.type = 'hidden';
    clientId.name = 'client_id';
    clientId.value = environment.clientId;
    form.appendChild(clientId);

    const clientSecret = document.createElement('input');
    clientSecret.type = 'hidden';
    clientSecret.name = 'client_secret';
    clientSecret.value = environment.clientSecret;
    form.appendChild(clientSecret);
    document.body.appendChild(form);
    form.submit();
  }

  loginViaGoogle() {
    this.oAuthLogin(googleAuthUrl);
  }

  loginViaFacebook() {
    this.oAuthLogin(facebookAuthUrl);
  }

  loginViaSaml() {
    this.oAuthLogin(samlAuthUrl);
  }
}
