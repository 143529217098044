<div class="el-form-item date-range">
  <label *ngIf="inputLabel" class="el-form-item__label" for="{{ id }}">{{
    label
  }}</label>
  <div class="el-form-item__content">
    <div
      class="el-input"
      [class]="{
        'el-input--prefix': prefixIcon || prefixSvg,
        'el-input--suffix': suffixIcon,
        'is-disabled': disabled,
      }"
    >
      <span *ngIf="prefixIcon" class="el-input__prefix">
        <i class="el-input__icon {{ prefixIcon }}"></i>
      </span>
      <input
        class="el-input__inner"
        type="text"
        matInput
        ngxDaterangepickerMd
        [locale]="{
          format: 'MM/DD/YYYY',
          separator: ' To ',
          applyLabel: 'Apply',
          clearLabel: 'Clear',
          cancelLabel: 'Cancel',
        }"
        [showCancel]="true"
        [closeOnAutoApply]="true"
        [autoApply]="true"
        [showClearButton]="true"
        [alwaysShowCalendars]="true"
        [singleDatePicker]="singleDatePicker"
        [ranges]="singleDatePicker ? singleRanges : ranges"
        [(ngModel)]="selected"
        [required]="required"
        [disabled]="disabled"
        readonly
        placeholder="Select Date"
        name="daterange"
        (ngModelChange)="onChange($event)"
      />
    </div>
    <div *ngIf="hint" class="hint">{{ hint }}</div>
  </div>
</div>
