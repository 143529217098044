import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {DonutDefaultConfig, PlanResultSummary} from '../models';
import {TargetFilterNameOptions} from '@simplifi/shared/enums';
import {Result} from '../models/result.model';
import {DONUT_CONSTANTS} from '../constants';
import {CreatePlanService} from '../services';
import moment from 'moment';
import {ResultTiles} from '../models/result-tiles.model';

export const desiredDonutPercentageKeyOrder = [
  TargetFilterNameOptions.Addressable,
  TargetFilterNameOptions.Contextual,
  TargetFilterNameOptions.Keywords,
  TargetFilterNameOptions.Ztv,
];

@Injectable()
export class PlanResultsAdapter implements IAdapter<Result> {
  resultTiles = new ResultTiles();
  constructor(private readonly createPlanService: CreatePlanService) {}
  /**
   * The function `adaptToModel` takes a response object and converts it into a Words object if the
   * response has an id property, otherwise it returns the response as is.
   * @param {any} resp - The `resp` parameter is of type `any`, which means it can be any type of object.
   * It is expected to have properties  such as `name`, `key`, `address`, `status`, `website`, `parentOrgId`
   * @returns The function `adaptToModel` returns an instance of the `Words` class if the `resp` object
   * has an `id` property. Otherwise, it returns the `resp` object itself.
   */

  public adaptToModel(resp: any): Result {
    const result = new Result();
    // Form the tiles data
    result.resultTiles = this.formTilesData(resp.summary, resp.blendedCpm);
    // Form the tactics data
    result.tacticsData.metrics = resp.tactics;
    result.tacticsData.percentages = resp.percentageSummary;
    result.tacticsData.totals.budget = resp.overAllTotalBudget;
    result.tacticsData.totals.cpm = resp.overAllTotalCpm;
    result.tacticsData.totals.impressions = resp.overAllTotalImpressions;
    result.tacticsData.totals.items = resp.overAllTotalItems;
    result.tacticsData.totals.households = resp.overAllTotalHouseholds;
    result.tacticsData.totals.population = resp.overAllTotalPopulation;
    result.tacticsData.totals.totals = resp.overAllTotalBudget;

    //Form the donut data
    const overAllTotalBudgetFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(+(resp.overAllTotalBudget ?? 0));

    const reorderedDonutPercentage = desiredDonutPercentageKeyOrder.map(key => {
      const index = resp.donutPercentageKey.indexOf(key);
      return Number(resp.donutPercentage[index]);
    });
    result.donutDatasets = {
      data: reorderedDonutPercentage ?? [],
      label: 'Budget Breakdown',
      chartLabels: desiredDonutPercentageKeyOrder,
      backgroundColor: DONUT_CONSTANTS.backGroundColor,
      hoverBackgroundColor: DONUT_CONSTANTS.hoverBackGroundColor,
      borderWidth: DONUT_CONSTANTS.border,
      donutTotalText: DONUT_CONSTANTS.totalText,
      donutTotalValue: resp.overAllTotalBudget
        ? overAllTotalBudgetFormat
        : '$0',
      donutDefaultConfig: new DonutDefaultConfig(),
      chartId: 'donutChart',
    };
    result.rowData = [resp.ztvResultSummary];
    this.createPlanService.plan.result = result;
    return result;
  }

  formTilesData(resultSummary: PlanResultSummary, blendedCpm: string) {
    const resultTiles = new ResultTiles();
    resultTiles['blendedCpm'].tileText = blendedCpm;
    resultTiles['householdReach'].tileText = String(
      resultSummary.totalHouseholds,
    );
    resultTiles['populationReach'].tileText = String(
      resultSummary.totalPopulations,
    );
    resultTiles['locationCount'].tileText = String(resultSummary.locationCount);

    resultTiles['flightDate'].tileText =
      `${moment(this.createPlanService.plan.startDate).format('MM/DD/YYYY')}-${moment(this.createPlanService.plan.endDate).format('MM/DD/YYYY')}`;
    return resultTiles;
  }

  adaptFromModel(data: Result): Result {
    return data;
  }
}
