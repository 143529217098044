export class StateManagement {
  id?: string;
  sessionId!: string;
  planId!: string;
  versionId!: string;
  activityTime!: Date;
  activityType!: PlanStateActivityType;
  data?: string;
  stateType!: StateSectionType;
  stateId?: string[];
  searchText?: string;
  constructor() {}
}

export enum PlanStateActivityType {
  list = 'list',
  select = 'select',
  remove = 'remove',
  selectAll = 'selectAll',
  removeAll = 'removeAll',
  clearList = 'clearList',
}

export const enum StateSectionType {
  KEYWORDS = 'keywords',
  ZTV = 'ztv',
  ADDRESSABLE = 'addressable',
  CONTEXTUAL = 'contextual',
  CITY = 'city',
  METRO = 'metro',
  ZIPCODE = 'zipcode',
}
