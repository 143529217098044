import {Messages} from '@simplifi/shared/constants';
import {Stepper} from '../enums';

export interface IWidgetStepper {
  active: boolean;
  complete: boolean;
  backRoute: Stepper | undefined;
  forwardRoute: Stepper | undefined;
  title: Stepper;
  nextButtonLabel: string;
  saveButtonTooltip: string;
  disabled: boolean;
  disableTooltip: string;
  allForwordRoutes: Stepper[];
}

export const WidgetStepper = {
  [Stepper.GENERAL]: {
    active: false,
    complete: false,
    backRoute: undefined,
    forwardRoute: Stepper.TARGET,
    title: Stepper.GENERAL,
    nextButtonLabel: 'Save & Next',
    saveButtonTooltip: Messages.createPlanMandatoryDisableToolTip,
    disabled: true,
    disableTooltip: '',
    allForwordRoutes: [Stepper.TARGET, Stepper.BUDGET, Stepper.RESULT],
  },
  [Stepper.TARGET]: {
    active: false,
    complete: false,
    backRoute: Stepper.GENERAL,
    forwardRoute: Stepper.BUDGET,
    title: Stepper.TARGET,
    nextButtonLabel: 'Save & Next',
    saveButtonTooltip: Messages.createPlanTargetMandatoryDisableToolTip,
    disabled: true,
    disableTooltip: Messages.createPlanMandatoryDisableToolTip,
    allForwordRoutes: [Stepper.BUDGET, Stepper.RESULT],
  },
  [Stepper.BUDGET]: {
    active: false,
    complete: false,
    backRoute: Stepper.TARGET,
    forwardRoute: Stepper.RESULT,
    title: Stepper.BUDGET,
    nextButtonLabel: 'Save & Go to Result',
    saveButtonTooltip: Messages.createPlanBudgetMandatoryDisableToolTip,
    disabled: true,
    disableTooltip: Messages.createPlanTargetMandatoryDisableToolTip,
    allForwordRoutes: [Stepper.RESULT],
  },
  [Stepper.RESULT]: {
    active: false,
    complete: false,
    backRoute: Stepper.BUDGET,
    forwardRoute: undefined,
    title: Stepper.RESULT,
    nextButtonLabel: '',
    saveButtonTooltip: '',
    disabled: true,
    disableTooltip: Messages.createPlanBudgetMandatoryDisableToolTip,
    allForwordRoutes: [],
  },
};
