export enum Numbers {
  numberZero = 0,
  numberOne = 1,
  numberTwo = 2,
  numberThree = 3,
  numberFour = 4,
  numberFive = 5,
  numberSix = 6,
  numberSeven = 7,
  numberEight = 8,
  numberNine = 9,
  numberTen = 10,
  numberEleven = 11,
  numberTwelve = 12,
  numberThirteen = 13,
  numberTwenty = 20,
  twentyfour = 24,
  twentyfive = 25,
  twentyNine = 29,
  forty = 40,
  fifty = 50,
  fiftyNine = 59,
  sixty = 60,
  seventyEight = 78,
  hundred = 100,
  twoHundred = 200,
  threeHundred = 300,
  fiveHundred = 500,
  eightHundred = 800,
  thousand = 1000,
  elevenHundred = 1100,
  twothousand = 2000,
  threeThousand = 3000,
  fourThousand = 4000,
  fiveThousand = 5000,
  tenThousand = 10000,
  onehundredfifty = 150,
  oneEightyThousand = 180000,
  numberThirty = 30,
  tenDigitNumber = 9999999999,
}
